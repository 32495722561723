<app-user-header
 [userHeaderProperties]="userHeaderProperties">
</app-user-header>


<div class="main-wrapper">
  <div class="background-container-registration">
    <div class="tab-box-container">
      <div class="tab-box-sub2">
        <div class="tab-box-sub">
          <div class="tab-box-body">
          <h1 ID="registrationHeading" style="text-align: center">{{registrationTemplate.RegistrationTitle || "registration"}}</h1>
          <div style="text-align: center">{{registrationTemplate.TypeOfAccountLabel || "which type of account would you like to setup?"}}</div>
          
            <div class="container">
              <div class="row  justify-content-center">
                <div class="col-lg-12" style="text-align:left;">
                  <form>
                    <div class="form-group" style="text-align:left;">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="radioIsCaloptimaMember"
                          name="accountTypeRadio"
                          class="custom-control-input"
                          [(ngModel)]="isCalOptimaMember" 
                          [value]="true">
                          <label class="custom-control-label" for="radioIsCaloptimaMember">
                              {{registrationTemplate.CalOptimaMemberOptionLabel || "i am a calOptima member"}}
                          </label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input 
                          type="radio" 
                          id="radioIsPersonalRep" 
                          name="accountTypeRadio" 
                          class="custom-control-input"
                          [(ngModel)]="isCalOptimaMember" 
                          [value]="false">
                          <label  class="custom-control-label" for="radioIsPersonalRep">
                            {{registrationTemplate.PersonalRepOptionLabel || "i am a personal representative for a CalOptima Member"}}
                          </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
        
              <div class="row justify-content-center">
                <div class="col-md-8" style="text-align:center; margin-bottom:50px; margin-top:20px;">
                <span style="margin-right: 20px; margin-left: 20px">
                  <button ID="btnBack" class="btn btn-secondary" (click)="onBack()">
                    {{registrationTemplate.BackButtonLabel || "back"}}
                  </button>
                </span>
                <span>
                  <button ID="btnToMemberRegistration" class="btn btn-primary" type="button" (click)="onClick()">
                    {{registrationTemplate.AccountTypeSubmitButtonLabel || "submit"}}
                  </button>
                </span>
              </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid justify-content-center" style="margin-top:60px;margin-bottom:200px;">
  <div class="row justify-content-center">
    <div class="col-md-8">
        <p style="text-align: center">
        {{registrationTemplate.Footer||'if you need further assistance, please contact CalOptima Customer Service at 1-88-587-8088'}}
        <a href="{{customerServicePhoneNumberLink}}">{{registrationTemplate.CustomerServicePhoneNumber}}</a>
        </p>
        
      </div>
  </div>
</div>



