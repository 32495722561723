import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
} from "@angular/core";
import { Configuration } from "../../app.constants";
import {
  LanguageService,
  INotifyChange,
} from "../../core/shared/services/language.service";
import {
  AuthenticationService,
  KnowledgeBase,
  UserProfile,
} from "@caloptima/authentication";
import { SecureAuthService } from "../shared/services/user.service";
import { UserProfileTemplate } from "../shared/user-profile-template";
import { CommonService } from "../../core/shared/services/common.service";
import { combineLatest, Subscription } from "rxjs";
import { ConfirmationService } from "primeng/api";
import { MemberProfileService } from "../../member-portal/shared/services/member-profile.service";
import { MemberProfile } from "../../member-portal/shared/member-profile";
import { OAuthService } from "angular-oauth2-oidc";
import { PhonePipe } from "../../core/shared/pipes/phone.pipe";
import { SecurityQuestionProperties } from "../shared/security-question-properties";
import { KBA } from "../shared/kba";
import { UpdateComplete } from "../shared/update-complete";
import { PasswordProperties } from "../shared/password-properties";
import { PasswordComponent } from "../shared/components/password/password.component";
import { SubSink } from "subsink";
import { PhoneViewProperties } from "./../../core/shared/phone-view-properties";
import { PhoneValidationMessages } from "../../core/shared/phone-validation-messages";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  providers: [CommonService, ConfirmationService],
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  @ViewChild(PasswordComponent) passComp: PasswordComponent;
  public errorMessage: string;
  public successMessage: string;
  private subscriptions = new SubSink();

  public sitecoreTemplate: UserProfileTemplate = new UserProfileTemplate();
  public memberProfile = new MemberProfile();
  public userProfile = new UserProfile();
  public kba = new KBA();
  public phonePipe = new PhonePipe();
  public securityQuestionProperties = new SecurityQuestionProperties();
  public passwordProperties = new PasswordProperties();
  public password: string;
  public isValidPassword: boolean;
  public isInvalidPhoneNumber: boolean;
  public phoneViewProperties: PhoneViewProperties = new PhoneViewProperties();
  public phoneValidationMessage: PhoneValidationMessages =
    new PhoneValidationMessages();

  constructor(
    private authService: AuthenticationService,
    private userProfileService: SecureAuthService,
    private languageService: LanguageService,
    private confirmationService: ConfirmationService,
    private memberProfileService: MemberProfileService,
    private oAuthService: OAuthService,
    private config: Configuration,
    private commonService: CommonService
  ) {}
  ngOnInit() {
    try {
      this.subscriptions.sink = combineLatest([
        this.languageService.notifyObservable$,
        this.memberProfileService.notifyObservable$,
      ]).subscribe((result) => {
        this.memberProfile = this.memberProfileService.memberProfile;
        if (this.memberProfile) this.onLoad();
      });
    } catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onLoad() {
    try {
      this.errorMessage = "";
      this.loadSitecoreContent();
      // window.scrollTo(0, 0);
      this.onReset(null);
    } catch (ex) {
      throw ex;
    }
  }

  private loadSitecoreContent() {
    this.userProfileService.getUserProfileTemplate().subscribe(
      (response) => {
        this.sitecoreTemplate = response;
        this.loadSecurityQuestionsView();
        this.loadPasswordView();
        this.loadChildProperties();
      },
      (error) => {
        this.errorMessage = <any>error;
      }
    );
  }

  loadChildProperties() {
    // this.phoneViewProperties.Title = "Contact number";
    this.phoneViewProperties.FieldLabel =
      this.sitecoreTemplate.ContactPhoneLabel ?? "Contact number";
    this.phoneViewProperties.UpdateMemberProfileText =
      this.sitecoreTemplate.UpdateMemberProfileText ??
      "Update Personal Information";

    this.phoneValidationMessage.InvalidInputMessage =
      this.sitecoreTemplate.MobilePhoneInvalidInputMessage;
    this.phoneValidationMessage.RequiredMessage =
      this.sitecoreTemplate.MobilePhoneRequiredMessage;
    this.phoneValidationMessage.MaxLengthMessage =
      this.sitecoreTemplate.MobilePhoneInvalidInputMessage;
  }

  loadUserProfile() {
    const claims = this.oAuthService.getIdentityClaims();
    const username = claims["sub"];

    this.userProfileService.getUserProfile(username).subscribe(
      (response) => {
        this.userProfile = response;
        this.userProfile.mobilePhone = this.commonService.GetPhoneValue(
          this.userProfile.mobilePhone
        );
        let newKBA = new KBA();

        if (
          this.userProfile.knowledgeBase &&
          this.userProfile.knowledgeBase[0]
        ) {
          newKBA.SecretQuestion1 = this.config.GetSecurityQuestion(
            this.userProfile.knowledgeBase[0].question
          );
          newKBA.SecretAnswer1 = this.userProfile.knowledgeBase[0].answer;
        } else {
          newKBA.SecretQuestion1 = this.config.SecurityQuestions[0];
        }

        if (
          this.userProfile.knowledgeBase &&
          this.userProfile.knowledgeBase[1]
        ) {
          newKBA.SecretQuestion2 = this.config.GetSecurityQuestion(
            this.userProfile.knowledgeBase[1].question
          );
          newKBA.SecretAnswer2 = this.userProfile.knowledgeBase[1].answer;
        } else {
          newKBA.SecretQuestion2 = this.config.SecurityQuestions[1];
        }
        this.kba = newKBA;
      },
      (error) => {
        this.errorMessage = <any>error;
        this.errorMessage = this.sitecoreTemplate.ErrorMessage;
      }
    );
  }

  loadMemberProfile() {
    this.memberProfileService.getMemberProfile().subscribe(
      (response) => {
        this.memberProfile = response;
      },
      (error) => {
        this.errorMessage = <any>error;
      }
    );
  }

  private loadSecurityQuestionsView(): void {
    // this.securityQuestionProperties.Required = true;
    this.securityQuestionProperties.HideAnswersLabel =
      this.sitecoreTemplate.HideAnswersLabel;
    this.securityQuestionProperties.Question1Label =
      this.sitecoreTemplate.SecretQuestion1;
    this.securityQuestionProperties.Question1AnswerLabel =
      this.sitecoreTemplate.SecretAnswer1;
    this.securityQuestionProperties.Question2Label =
      this.sitecoreTemplate.SecretQuestion2;
    this.securityQuestionProperties.Question2AnswerLabel =
      this.sitecoreTemplate.SecretAnswer2;
    this.securityQuestionProperties.SecurityQuestionsDropdownMessageLabel =
      this.sitecoreTemplate.SecurityQuestionsDropdownMessageLabel;
    this.securityQuestionProperties.AnswerRequiredMessage =
      this.sitecoreTemplate.AnswerRequiredMessage;

    this.config.SecurityQuestions[0].text = this.sitecoreTemplate.Question1;
    this.config.SecurityQuestions[1].text = this.sitecoreTemplate.Question2;
    this.config.SecurityQuestions[2].text = this.sitecoreTemplate.Question3;
    this.config.SecurityQuestions[3].text = this.sitecoreTemplate.Question4;
    this.config.SecurityQuestions[4].text = this.sitecoreTemplate.Question5;
    this.config.SecurityQuestions[5].text = this.sitecoreTemplate.Question6;
    this.config.SecurityQuestions[6].text = this.sitecoreTemplate.Question7;
    this.config.SecurityQuestions[7].text = this.sitecoreTemplate.Question8;
    this.config.SecurityQuestions[8].text = this.sitecoreTemplate.Question9;
    this.config.SecurityQuestions[9].text = this.sitecoreTemplate.Question10;

    this.securityQuestionProperties.Question1Values =
      this.config.SecurityQuestions;
    this.securityQuestionProperties.Question2Values =
      this.config.SecurityQuestions;
  }

  private loadPasswordView(): void {
    this.passwordProperties.PasswordLabelText =
      this.sitecoreTemplate.NewPasswordLabel;
    this.passwordProperties.ConfirmPasswordLabelText =
      this.sitecoreTemplate.ConfirmNewPasswordLabel;
    this.passwordProperties.PasswordMeetsCritieraSuccessMessage =
      this.sitecoreTemplate.PasswordMeetsCritieraSuccessMessage;
    this.passwordProperties.PasswordFormatError =
      this.sitecoreTemplate.PasswordFormatError;
    this.passwordProperties.PasswordLengthErrorMessage =
      this.sitecoreTemplate.PasswordLengthErrorMessage;
    this.passwordProperties.UsernameAndPasswordCannotBeTheSameError =
      this.sitecoreTemplate.UsernameAndPasswordCannotBeTheSameError;
    this.passwordProperties.PasswordMatchSuccessMessage =
      this.sitecoreTemplate.PasswordMatchSuccessMessage;
    this.passwordProperties.PasswordNoMatchError =
      this.sitecoreTemplate.PasswordDontMatchErrorMessage;
    this.passwordProperties.PasswordLengthMessage =
      this.sitecoreTemplate.PasswordLengthMessage;
    this.passwordProperties.PasswordMeetNumericalMessage =
      this.sitecoreTemplate.PasswordMeetNumericalMessage;
    this.passwordProperties.PasswordMeetSymbolMessage =
      this.sitecoreTemplate.PasswordMeetSymbolMessage;
    this.passwordProperties.PasswordMeetUpperAlphaMessage =
      this.sitecoreTemplate.PasswordMeetUpperAlphaMessage;
    this.passwordProperties.PasswordMeetLowerAlphaMessage =
      this.sitecoreTemplate.PasswordMeetLowerAlphaMessage;
    this.passwordProperties.PasswordMeetComplexityMessage =
      this.sitecoreTemplate.PasswordMeetComplexityMessage;

    //this.passwordProperties.LabelStyle = 'no-top-margin';
    this.passwordProperties.InputContainerRowStyle = "row";
    this.passwordProperties.InputContainerStyle = "col-md-6";
    this.passwordProperties.InputStyle = "form-control";
    this.passwordProperties.AcceptanceCriteriaLabel = "";
    this.passwordProperties.AcceptanceCriteriaContainerStyle = "user-tab-body";
    this.passwordProperties.ShowGreyCircle = false;
  }

  public onPhoneChange($event): void {
    this.userProfile.mobilePhone = $event;
    this.isInvalidPhoneNumber = this.commonService.isInvalidPhoneNumber(
      this.userProfile.mobilePhone
    );
  }

  public onPasswordChange($event): void {
    this.password = $event.password;
    this.isValidPassword = $event.isValid;
  }

  onSubmit(form) {
    if (form.invalid) {
      return;
    }

    this.userProfile.mobilePhone = this.commonService.GetPhoneValue(
      this.userProfile.mobilePhone
    );
    this.userProfile.knowledgeBase = new Array<KnowledgeBase>();

    let sec1: KnowledgeBase = {
      question: this.kba.SecretQuestion1.value,
      answer: this.kba.SecretAnswer1,
    };
    let sec2: KnowledgeBase = {
      question: this.kba.SecretQuestion2.value,
      answer: this.kba.SecretAnswer2,
    };
    this.userProfile.knowledgeBase.push(sec1);
    this.userProfile.knowledgeBase.push(sec2);

    this.userProfileService.updateUserProfile(this.userProfile).subscribe(
      (response) => {
        this.successMessage = this.sitecoreTemplate.SuccessMessage;
        this.updateUserProfileComplete();
      },
      (error) => {
        this.errorMessage = <any>error;
        this.errorMessage = this.sitecoreTemplate.ErrorMessage;
      }
    );
  }

  updateUserProfileComplete() {
    const request: UpdateComplete = { UserName: this.userProfile.userID };

    this.userProfileService.updateUserProfileComplete(request).subscribe();
    window.scrollTo(0, 0);
  }

  confirmFormReset(form) {
    this.confirmationService.confirm({
      message:
        this.sitecoreTemplate?.ConfirmFormResetMessage ||
        "are you sure that you want to proceed?",
      header: this.sitecoreTemplate?.ConfirmFormResetHeader || "confirmation",
      // icon: 'pi pi-exclamation-triangle',
      // acceptLabel: 'yes',
      // rejectLabel: 'no',
      accept: () => {
        this.onReset(form);
      },
      reject: () => {},
    });
  }

  onReset(form) {
    this.loadUserProfile();

    this.securityQuestionProperties.Question1Values =
      this.config.SecurityQuestions;
    this.securityQuestionProperties.Question2Values =
      this.config.SecurityQuestions;
  }

  onPasswordCancel(form) {
    this.confirmationService.confirm({
      message:
        this.sitecoreTemplate?.ConfirmFormResetMessage ||
        "are you sure that you want to proceed?",
      header: this.sitecoreTemplate?.ConfirmFormResetHeader || "confirmation",
      // icon: 'pi pi-exclamation-triangle',
      // acceptLabel: 'yes',
      // rejectLabel: 'no',
      accept: () => {
        this.passComp.ResetForm();
      },
      reject: () => {},
    });
  }

  public onPasswordUpdate(): void {
    this.errorMessage = "";
    this.successMessage = "";
    if (!this.isValidPassword) return;

    this.userProfileService
      .updateUserProfilePassword(this.userProfile.userID, this.password)
      .subscribe(
        (response) => {
          if (response === true) {
            this.successMessage =
              this.sitecoreTemplate?.UpdatePasswordSuccess ||
              this.sitecoreTemplate?.SuccessMessage;
            //this.updateUserProfileComplete();
          } else {
            this.errorMessage =
              this.sitecoreTemplate?.UpdatePasswordError ||
              "error!  You cannot use one of your last three (3) passwords.  Please input a new password.";
          }
        },
        (error) => {
          this.errorMessage = error;
        }
      );

    window.scrollTo(0, 0);
  }
}
