import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MemberIdProperties } from '../../memberid-properties';
import { DialogModule } from 'primeng/dialog';

declare var $: any;

@Component({
  selector: 'app-member-id',
  templateUrl: './member-id.component.html',
  styleUrls: ['./member-id.component.scss']
})
export class MemberIdComponent implements OnInit {

  constructor() { }

  @Input() memberIdProperties = new MemberIdProperties();
  @Output() emitMemberId = new EventEmitter();
  public display: boolean = false;
  public isValidMemberId: boolean;

  ngOnInit() {
  }

  public showCard() {
    this.display = true;
  }

  public onMemberIdChange(isInvalidMemberId: boolean) {
    if (isInvalidMemberId) {
      this.isValidMemberId = false;
    } else {
      this.isValidMemberId = true;
    }

    this.emitMemberId.emit({
      memberIdValue: this.memberIdProperties.MemberIdValue,
      isValid: this.isValidMemberId
    });
  }
}
