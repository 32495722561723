<div class="form-group">
  <label class="col-form-label" for="EmailValue">
    {{emailProperties.EmailLabelText || "email"}}
  </label>
  <div class="input-message">
    {{emailProperties.Message || 'please provide a valid email that can be use for password retrieval'}}
  </div>
  <input type="text"
          class="form-control"
          id="EmailValue"
          name="EmailValue"
          spellcheck="false"
          tabindex="{{emailProperties.TabIndex}}"
          #EmailValue="ngModel"
          placeholder="{{emailProperties.EmailPlaceholder ||'enter email'}}"
          [ngClass]="{'is-invalid': EmailValue.invalid && EmailValue.touched && EmailValue.dirty}"
          [(ngModel)]="emailProperties.EmailValue"
          (ngModelChange)="onEmailChange(EmailValue.invalid && (EmailValue.touched || EmailValue.dirty))"
          pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
          required>
    <label ID="EmailErrorMsg" *ngIf="EmailValue.errors && EmailValue.touched && EmailValue.dirty"
            class="invalid-feedback">
        {{emailProperties.ErrorMessage ||'invalid email'}}
    </label>
</div>
