
  <app-user-header
  [userHeaderProperties]="userHeaderProperties">
  </app-user-header>

<!-- Main Registration Wizard Form-->
<div class="container justify-content-center user-main-registration">
  
  <div class="user-form-body">
    <div class="registration-frame" *ngIf="stepNumber < 7">
      <div class="container">
        <div class="progress-row progress-background">
            <div class="progress-column">
                <div>
                    <div class="progress-left" [ngClass]="{active: stepNumber >= 1}"></div>
                    <div class="progress-indicator" [ngClass]="{active: stepNumber >= 1}">
                        <div class="progress-check fa-duotone fa-check add-cursor" *ngIf="stepNumber > 1" (click)="goBacktoStep1()">
                        </div>
                    </div>
                    <div class="progress-right" [ngClass]="{active: stepNumber >= 2}"></div>
                </div>
            </div>
            <div class="progress-column">
                <div>
                    <div class="progress-left" [ngClass]="{active: stepNumber >= 2}"></div>
                    <div class="progress-indicator" [ngClass]="{active: stepNumber >= 2}">
                        <div class="progress-check fa-duotone fa-check add-cursor" *ngIf="stepNumber > 2" (click)="goBacktoStep2()"></div>
                    </div>
                    <div class="progress-right" [ngClass]="{active: stepNumber >= 3}"></div>
                </div>
            </div>
            <div class="progress-column">
                <div>
                    <div class="progress-left" [ngClass]="{active: stepNumber >= 3}"></div>
                    <div class="progress-indicator" [ngClass]="{active: stepNumber >= 3}">
                        <div class="progress-check fa-duotone fa-check add-cursor" *ngIf="stepNumber > 3" (click)="goBacktoStep3()"></div>
                    </div>
                    <div class="progress-right" [ngClass]="{active: stepNumber >= 4}"></div>
                </div>
            </div>
            <div class="progress-column">
                <div>
                    <div class="progress-left" [ngClass]="{active: stepNumber >= 4}"></div>
                    <div class="progress-indicator" [ngClass]="{active: stepNumber >= 4}">
                        <div class="progress-check fa-duotone fa-check add-cursor" *ngIf="stepNumber > 4" (click)="goBacktoStep4()"></div>
                    </div>
                    <div class="progress-right" [ngClass]="{active: stepNumber >= 5}"></div>
                </div>
            </div>
            <div class="progress-column">
                <div>
                    <div class="progress-left" [ngClass]="{active: stepNumber >= 5}"></div>
                    <div class="progress-indicator" [ngClass]="{active: stepNumber >= 5}">
                        <div class="progress-check fa-duotone fa-check" *ngIf="stepNumber > 5"></div>
                    </div>
                    <div class="progress-right" [ngClass]="{active: stepNumber >= 6}"></div>
                </div>
            </div>
            <div class="progress-column">
              <div>
                  <div class="progress-left" [ngClass]="{active: stepNumber >= 6}"></div>
                  <div class="progress-indicator" [ngClass]="{active: stepNumber >= 6}">
                      <div class="progress-check fa-duotone fa-check" *ngIf="isSuccessfulRegister"></div>
                  </div>
                  <div class="progress-right" [ngClass]="{active: isSuccessfulRegister}"></div>
              </div>
          </div>
        </div>
      </div>
      <form #registrationForm="ngForm">
        <fieldset>
          <div>

            <!-- STEP 1: Member Information Step -->
            <div *ngIf="stepNumber == 1">
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-md-12">
                    <h1 ID="memberInfoTitle" style="text-align: center">
                      {{registrationTemplate.MemberInformationTitle || "member information"}}
                    </h1>
                    <div class="middle-step-container">

                      <div *ngIf="isStepOneSubmit && !isAllRequiredFieldsStepOne" class="alert alert-danger">
                          <i class="fa-duotone fa-ban"aria-hidden="true"></i>
                          <strong>Error!</strong>
                          {{registrationTemplate.RequiredMessage}}
                      </div>
                      <p>
                        {{registrationTemplate.PersonalRepMemberInfoDisclaimer1}}
                      </p>
                      <p>
                        {{registrationTemplate.PersonalRepMemberInfoDisclaimer2}}
                      </p>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class=" col-form-label" for="memberFirstName">
                              {{registrationTemplate.FirstName || "member first name"}}
                            </label>
                            <input
                              (keydown.enter)="keyDownFunction($event)"
                              id="memberFirstName"
                              name="memberFirstName"
                              type="text"
                              class="form-control"
                              tabindex ="{{memberFirstNameTabIndex}}"
                              placeholder="{{registrationTemplate.FirstnamePlaceholder ||'first name*'}}"
                              pattern="^[A-Za-z\s\-\']+$"
                              #memberFirstName="ngModel"
                              [ngClass]="{'is-invalid': memberFirstName.invalid && memberFirstName.touched && memberFirstName.dirty}"
                              [(ngModel)]="registration.FirstName"
                              (ngModelChange)="onMemberFirstnameChange(memberFirstName.invalid && (memberFirstName.touched || memberFirstName.dirty))"
                              required>
                            <label ID="memberFirstNameErrorMsg" *ngIf="memberFirstName.invalid && memberFirstName.touched && memberFirstName.dirty"
                                    class="invalid-feedback">
                              {{registrationTemplate.InvalidFormatError ||'Error'}}
                            </label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <app-user-phone
                            [phoneProperties]="phoneProperties"
                            (emitPhone)="onMemberPhoneChange($event)"
                            (keydown.enter)="keyDownFunction($event)">
                          </app-user-phone>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class=" col-form-label" for="memberLastName">
                              {{registrationTemplate.LastName || "member last name"}}
                            </label>
                            <input
                              (keydown.enter)="keyDownFunction($event)"
                              id="memberLastName"
                              name="memberLastName"
                              type="text"
                              class="form-control"
                              tabindex="{{memberLastNameTabIndex}}"
                              placeholder="{{registrationTemplate.LastnamePlaceholder ||'last name*'}}"
                              pattern="^[A-Za-z\s\-\']+$"
                              #memberLastName="ngModel"
                              [ngClass]="{'is-invalid': memberLastName.invalid && memberLastName.touched && memberLastName.dirty}"
                              [(ngModel)]="registration.LastName"
                              (ngModelChange)="onMemberLastnameChange(memberLastName.invalid && (memberLastName.touched || memberLastName.dirty))"
                              required>
                            <label
                              ID="memberLastNameErrorMsg"
                              *ngIf="memberLastName.invalid && memberLastName.touched && memberLastName.dirty"
                              class="invalid-feedback">
                              {{registrationTemplate.InvalidFormatError ||'Error'}}
                            </label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <app-member-id
                            [memberIdProperties]="memberIdProperties"
                            (emitMemberId)="onMemberIdChange($event)"
                            (keydown.enter)="keyDownFunction($event)">
                          </app-member-id>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                              <label class=" col-form-label" for="memberZipCode">
                                {{registrationTemplate.ZipCode || "zip code"}}
                              </label>
                              <input
                                (keydown.enter)="keyDownFunction($event)"
                                id="memberZipCode"
                                name="memberZipCode"
                                type="text"
                                class="form-control"
                                style="width:240px"
                                tabindex ="{{memberZipCodeTabIndex}}"
                                placeholder="{{registrationTemplate.ZipCodePlaceholder ||'zip code*'}}"
                                pattern="^\d{5}"
                                #memberZipCode="ngModel"
                                [ngClass]="{'is-invalid': memberZipCode.invalid && memberZipCode.touched && memberZipCode.dirty}"
                                [(ngModel)]="registration.ZipCode"
                                (ngModelChange)="onMemberZipCodeChange(memberZipCode.invalid && (memberZipCode.touched || memberZipCode.dirty))"
                                required>
                              <label
                                ID="memberZipCodeErrorMsg"
                                *ngIf="memberZipCode.invalid && memberZipCode.touched && memberZipCode.dirty"
                                class="invalid-feedback">
                                {{registrationTemplate.InvalidFormatError ||'Error'}}
                              </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                          <app-date-of-birth
                            [dateOfBirthProperties]="memberDobProperties"
                            (dateOfBirthEValue)="onMemberDobChanged($event)"
                            (keydown.enter)="keyDownFunction($event)">
                          </app-date-of-birth>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 text-right">
                      <div class="col-md-12">
                        <button
                          ID="btnBackToUserType"
                          class="btn btn-link"
                          type="button"
                          (click)="redirectToMainRegistration()">
                          {{registrationTemplate.BackButtonLabel ||'back'}}
                        </button>
                        <button
                          ID="btnToSecondStep"
                          class="btn btn-primary"
                          type="submit"
                          tabindex="{{step1ForwardTabIndex}}" 
                          (click)="ValidateFirstStep()">
                          {{registrationTemplate.NextButtonLabel ||'next'}}
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <!-- STEP 2: Personal Rep Information Step -->
            <div *ngIf="stepNumber == 2">
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-md-12">
                    <h1 ID="prepInfoTitle" style="text-align: center">
                      {{registrationTemplate.PersonalRepInformationTitle || "personal representative information"}}
                    </h1>
                    <div class="middle-step-container">
                      <div *ngIf="isStepTwoSubmit && !isValidPRepDobRange" class="alert alert-danger">
                        <i class="fa-duotone fa-ban" aria-hidden="true"></i>
                        <strong>Error!</strong>
                        {{registrationTemplate.PersonalRepIsUnder18ErrorMessage}}
                      </div>
                      <div *ngIf="isStepTwoSubmit && !isAllRequiredFieldsStepTwo" class="alert alert-danger">
                        <i class="fa-duotone fa-ban"aria-hidden="true"></i>
                        <strong>Error!</strong>
                        {{registrationTemplate.RequiredMessage}}
                      </div>
                      <p>
                        {{registrationTemplate.PersonalRepRepInfoDisclaimer}}
                      </p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class=" col-form-label" for="prepFirstName">
                              {{registrationTemplate.PersonalRepFirstNameLabel}}
                            </label>
                            <input
                              (keydown.enter)="keyDownFunction($event)"
                              id="prepFirstName"
                              name="prepFirstName"
                              type="text"
                              class="form-control"
                              tabindex="{{prepFirstNameTabIndex}}"
                              placeholder="{{registrationTemplate.FirstnamePlaceholder ||'first name*'}}"
                              pattern="^[A-Za-z\s\-\']+$"
                              #prepFirstName="ngModel"
                              [ngClass]="{'is-invalid': prepFirstName.invalid && prepFirstName.touched && prepFirstName.dirty}"
                              [(ngModel)]="registration.personalRepresentative.FirstName"
                              (ngModelChange)="onPRepFirstnameChange(prepFirstName.invalid && (prepFirstName.touched || prepFirstName.dirty))"
                              required>
                            <label
                              ID="firstNameErrorMsg"
                              *ngIf="prepFirstName.invalid && prepFirstName.touched && prepFirstName.dirty"
                              class="invalid-feedback">
                              {{registrationTemplate.InvalidFormatError ||'Error'}}
                            </label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class=" col-form-label" for="prepAddress">
                              {{registrationTemplate.PersonalRepStreetLabel ||'your address'}}
                            </label>
                            <input
                              (keydown.enter)="keyDownFunction($event)"
                              id="prepAddress"
                              name="prepAddress"
                              type="text"
                              class="form-control"
                              tabindex="{{prepAddressTabIndex}}"
                              placeholder="{{registrationTemplate.PersonalRepStreetLabel ||'your address'}}"
                              pattern="^\d+\s[a-zA-Z\s0-9\.\,\-\#]*$"
                              #prepAddress="ngModel"
                              [ngClass]="{'is-invalid': prepAddress.invalid && prepAddress.touched && prepAddress.dirty}"
                              [(ngModel)]="registration.personalRepresentative.Address"
                              (ngModelChange)="onPrepAddressChange(prepAddress.invalid && (prepAddress.touched || prepAddress.dirty))"
                              required>
                            <label
                              ID="prepAddressErrorMsg"
                              *ngIf="prepAddress.invalid && prepAddress.touched && prepAddress.dirty"
                              class="invalid-feedback">
                              {{registrationTemplate.InvalidFormatError ||'Error'}}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class=" col-form-label" for="middleInitial">
                              {{registrationTemplate.MiddleInitial || "middle initial (optional)"}}
                            </label>
                            <input
                              (keydown.enter)="keyDownFunction($event)"
                              id="middleInitial"
                              name="middleInitial"
                              type="text"
                              class="form-control"
                              tabindex="{{prepMiddleInitialTabIndex}}"
                              placeholder="{{registrationTemplate.MiddleInitialPlaceholder ||'mi*'}}"
                              maxlength="1"
                              pattern="^[A-Za-z]?"
                              style="width:60px"
                              #middleInitial="ngModel"
                              [ngClass]="{'is-invalid': middleInitial.invalid && middleInitial.touched && middleInitial.dirty}"
                              [(ngModel)]="registration.personalRepresentative.MiddleInitial"
                              (ngModelChange)="onMiddleInitialChange(middleInitial.invalid && (middleInitial.touched || middleInitial.dirty))" >
                              <label
                                ID="middleInitialErrorMsg"
                                *ngIf="middleInitial.invalid && middleInitial.touched && middleInitial.dirty"
                                class="invalid-feedback">
                                {{registrationTemplate.InvalidFormatError ||'Error'}}
                              </label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class=" col-form-label" for="prepCity">
                              {{registrationTemplate.PersonalRepCityLabel ||'your city'}}
                            </label>
                            <input
                              (keydown.enter)="keyDownFunction($event)"
                              id="prepCity"
                              name="prepCity"
                              type="text"
                              class="form-control"
                              tabindex="{{prepCityTabIndex}}"
                              placeholder="{{registrationTemplate.PersonalRepCityLabel ||'your city'}}"
                              pattern="^[A-Za-z\s\-]+$"
                              #prepCity="ngModel"
                              [ngClass]="{'is-invalid': prepCity.invalid && prepCity.touched && prepCity.dirty}"
                              [(ngModel)]="registration.personalRepresentative.City"
                              (ngModelChange)="onPrepCityChange(prepCity.invalid && (prepCity.touched || prepCity.dirty))"
                              required>
                            <label
                              ID="prepCityErrorMsg"
                              *ngIf="prepCity.invalid && prepCity.touched && prepCity.dirty"
                              class="invalid-feedback">
                              {{registrationTemplate.InvalidFormatError ||'Error'}}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class=" col-form-label" for="prepLastName">
                              {{registrationTemplate.PersonalRepLastNameLabel ||'your last name'}}
                            </label>
                            <input
                              (keydown.enter)="keyDownFunction($event)"
                              id="prepLastName"
                              name="prepLastName"
                              type="text"
                              class="form-control"
                              tabindex="{{prepLastNameTabIndex}}"
                              placeholder="{{registrationTemplate.LastnamePlaceholder ||'last name*'}}"
                              pattern="^[A-Za-z\s\-\']+$"
                              #prepLastName="ngModel"
                              [ngClass]="{'is-invalid': prepLastName.invalid && prepLastName.touched && prepLastName.dirty}"
                              [(ngModel)]="registration.personalRepresentative.LastName"
                              (ngModelChange)="onPRepLastnameChange(prepLastName.invalid && (prepLastName.touched || prepLastName.dirty))"
                              required>
                            <label
                              ID="lastNameErrorMsg"
                              *ngIf="prepLastName.invalid && prepLastName.touched && prepLastName.dirty"
                              class="invalid-feedback">
                              {{registrationTemplate.InvalidFormatError ||'Error'}}
                            </label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class=" col-form-label" for="prepState">
                              {{registrationTemplate.PersonalRepStateLabel || 'your state'}}
                            </label>
                            <select
                              id="prepState"
                              name="prepState"
                              class="form-control"
                              tabindex="{{prepStateTabIndex}}"
                              [(ngModel)]="registration.personalRepresentative.State">
                              <option value="-1">{{registrationTemplate.PersonalRepStateLabel || 'your state'}}</option>
                              <option
                                *ngFor="let state of states"
                                [ngValue]="state.abbreviation">
                                  {{state.name}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class=" col-form-label" for="relationToMember">
                              {{registrationTemplate.RelationToMemberLabel || "relation to member"}}
                            </label>
                            <select
                              id="relationToMember"
                              name="relationToMember"
                              class="form-select"
                              #relationToMember="ngModel"
                              tabindex="{{prepRelationshipTabIndex}}"
                              [(ngModel)]="registration.personalRepresentative.RelationshipId">
                              <option
                                *ngFor="let relation of familyRelationshipList"
                                [ngValue]="relation.Code">
                                {{relation.Description}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class=" col-form-label" for="prepZipCode">
                              {{registrationTemplate.PersonalRepZipCodeLabel || "your zip code"}}
                            </label>
                            <input
                              (keydown.enter)="keyDownFunction($event)"

                              id="prepZipCode"
                              name="prepZipCode"
                              type="text"
                              class="form-control"
                              tabindex="{{prepZipCodeTabIndex}}"
                              style="width:240px"
                              placeholder="{{registrationTemplate.PersonalRepZipCodePlaceholder ||'zip code*'}}"
                              #prepZipCode="ngModel"
                              pattern="^\d{5}"
                              [ngClass]="{'is-invalid': prepZipCode.invalid && prepZipCode.touched && prepZipCode.dirty}"
                              [(ngModel)]="registration.personalRepresentative.ZipCode"
                              (ngModelChange)="onPRepZipCodeChange(prepZipCode.invalid && (prepZipCode.touched || prepZipCode.dirty))"
                              required>
                            <label
                              ID="zipCodeErrorMsg"
                              *ngIf="prepZipCode.invalid && prepZipCode.touched && prepZipCode.dirty"
                              class="invalid-feedback">
                              {{registrationTemplate.InvalidFormatError ||'Error'}}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <app-date-of-birth
                            [dateOfBirthProperties]="dateOfBirthPRepProperties"
                            (dateOfBirthEValue)="dateOfBirthPRepChanged($event)"
                            (keydown.enter)="keyDownFunction($event)">
                          </app-date-of-birth>
                        </div>
                        <div class="col-md-6">
                          <app-user-phone
                            [phoneProperties]="phonePRepProperties"
                            (emitPhone)="onPRepPhoneChange($event)"
                            (keydown.enter)="keyDownFunction($event)">
                          </app-user-phone>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 text-right">
                      <div class="col-md-12">
                        <button
                          ID="btnGoBackToFirstStep"
                          class="btn btn-link"
                          (click)="goBacktoStep1()">
                          {{registrationTemplate.BackButtonLabel ||'back'}}
                        </button>
                        <button
                          ID="btnGoToThirdStep"
                          class="btn btn-primary"
                          type="submit"
                          tabindex="{{step2ForwardTabIndex}}" 
                          (click)="validateSecondStep()">
                          {{registrationTemplate.NextButtonLabel ||'next'}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SETP 3: User Information Step -->
            <div *ngIf="stepNumber == 3">
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-md-12">
                    <h1 ID="userInfoTitle" style="text-align: center">
                      {{registrationTemplate.UserInformationTitle ||'user information'}}
                    </h1>

                    <div class="middle-step-container">
                      <div *ngIf="isStepThreeSubmit && !isAllRequiredFieldsStepThree" class="alert alert-danger">
                          <i class="fa-duotone fa-ban"aria-hidden="true"></i>&nbsp;
                          <strong>Error!</strong>&nbsp;
                          {{registrationTemplate.RequiredMessage}}
                      </div>
                      <div *ngIf="isStepThreeSubmit && !isValidUserNamePassword" class="alert alert-danger">
                        <i class="fa-duotone fa-ban"aria-hidden="true"></i>&nbsp;
                        <strong>Error!</strong>&nbsp;
                        {{registrationTemplate.UsernameAndPasswordCannotBeTheSameError}}
                      </div>
                      <p>
                        {{registrationTemplate.PersonalRepUserInfoDisclaimer}}
                      </p>
                      <div class="row">
                        <div class="col-md-6">
                          <app-username
                            [userNameProperties]="userNameProperties"
                            [IsValidUserNamePassword]="isValidUserNamePassword"
                            (emitUserName)="onUserNameChange($event)"
                            (keydown.enter)="keyDownFunction($event)">
                          </app-username>
                        </div>
                        <div class="col-md-6">
                          <app-user-email
                            [emailProperties]="emailProperties"
                            (emitEmail)="onEmailChange($event)"
                            (keydown.enter)="keyDownFunction($event)">
                          </app-user-email>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <app-password
                            [passwordProperties]="passwordProperties"
                            (emitPassword)="onPasswordChange($event)"
                            [userName]="registration.UserName"
                            (keydown.enter)="keyDownFunction($event)">
                          </app-password>
                        </div>
                        <div class="col-md-6">
                          <app-user-phone
                            [phoneProperties]="userPhoneProperties"
                            (emitPhone)="onPhoneUserChange($event)"
                            (keydown.enter)="keyDownFunction($event)">
                          </app-user-phone>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 text-right">
                      <div class="col-md-12">
                          <button
                            ID="btnGoBackToSecondStep"
                            class="btn btn-link"
                            (click)="goBacktoStep2()">
                            {{registrationTemplate.BackButtonLabel ||'back'}}
                          </button>
                          <button
                            ID="btnGoToFourthStep"
                            class="btn btn-primary"
                            type="submit"
                            tabindex="{{step3ForwardTabIndex}}" 
                            (click)="validateThirdStep()">
                            {{registrationTemplate.NextButtonLabel ||'next'}}
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- STEP 4: Security Questions- Step -->
            <div *ngIf="stepNumber == 4">
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-md-8">
                    <h1 ID="successMessageTitle" style="text-align: center">
                      {{registrationTemplate.SecurityQuestionsLabel ||'security questions'}}
                    </h1>

                    <div class="middle-step-container">
                        <p>
                          {{registrationTemplate.PersonalRepSecurityQuestionsDisclaimer}}
                        </p>
                      <app-security-question 
                        name="securityQuestion" id="securityQuestion"
                        #securityQuestions [(ngModel)] = "kba"
                        [securityQuestionProperties]="securityQuestionProperties"
                        (ngModelChange)="onSecurityQuestionChange($event)"
                        (keydown.enter)="keyDownFunction($event)">
                      </app-security-question>
                    </div>
                    <div class="col-md-12 text-right">
                      <div class="col-md-12">
                          <button
                            ID="btnGoBackToThirdStep"
                            class="btn btn-link" 
                            (click)="goBacktoStep3()">
                            {{registrationTemplate.BackButtonLabel ||'back'}}
                          </button>
                          <button
                            ID="btnGoToFifthStep"
                            class="btn btn-primary"
                            tabindex={{step4ForwardTabIndex}}
                            type="submit" 
                            (click)="stepNumber = 5">
                            {{registrationTemplate.NextButtonLabel ||'next'}}
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- STEP 5: Attestation -->
            <div *ngIf="stepNumber == 5">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                      <div class="col-md-12">
                        <h1 ID="declarationAndAckTitle" style="text-align: center">
                            {{registrationTemplate.AttestationTitle ||'declaration and acknowledgement'}}
                        </h1>
                        <div class="attestation-message mb-4 mt-4">
                          <span [innerHtml]="registrationTemplate.AttestationMessage"></span>
                        </div>

                          <div class="form-group mb-4">
                            <div class="custom-control custom-checkbox">
                              <input
                                (keydown.enter)="keyDownFunction($event)"
                                id="attestationChk"
                                type="checkbox"
                                class="custom-control-input"
                                tabindex={{declarationCheckBoxTabIndex}}
                                (change)="isValidAttestation = !isValidAttestation">
                              <label class="custom-control-label unbold-label" for="attestationChk">
                                <strong>{{registrationTemplate.AttestationAgreeLabel || 'i Agree'}}</strong>
                              </label>
                            </div>
                          </div>

                        <div class="col-md-12 text-right">
                          <div class="col-md-12">
                            <button
                              ID="btnGoBackToFourthStep"
                              class="btn btn-link" 
                              (click)="goBacktoStep4()">
                              {{registrationTemplate.BackButtonLabel ||'back'}}
                            </button>
                            <button
                              ID="btnRegister"
                              class="btn btn-primary"
                              type="submit"
                              tabindex={{registerTabIndex}}
                              [disabled]="!isValidAttestation"
                              (click)="validateRegister()">
                              {{registrationTemplate.RegisterButton||'submit'}}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>

            <!-- STEP 6: Thank you and completion Step-->
            <div *ngIf="stepNumber == 6">
                <div class="container-fluid" *ngIf="hasRegistrationOccurred">
                  <div class="row justify-content-center">
                    <div class="col-md-12">
                      <h1 ID="afterRegistrationMessageTitle"  style="text-align: center">
                          {{registrationTemplate.PostRegistrationTitle}}
                      </h1>
                      <div class="middle-step-container">
                        <div *ngIf="!isSuccessfulRegister && !restrictedMessage" class="alert alert-danger">
                          <i class="fa-duotone fa-ban" aria-hidden="true"></i>
                          <strong>Error!</strong>
                          {{registrationTemplate.PostRegistrationAlertErrorMessage}}
                        </div>
                        <div *ngIf="!isSuccessfulRegister && restrictedMessage" class="alert alert-danger">
                          <i class="fa-duotone fa-ban" aria-hidden="true"></i>
                          <strong>Error!</strong>
                          {{restrictedMessage}}
                        </div>
                        <div *ngIf="isSuccessfulRegister" class="alert alert-success">
                            <i class="fa-duotone fa-check" aria-hidden="true"></i>
                            <strong>Success!</strong>
                            {{registrationTemplate.PostRegistrationAlertSuccessMessage}}
                        </div>
                        <h2 *ngIf="isSuccessfulRegister">
                            {{registrationTemplate.PostRegistrationSuccessSubTitle}}
                        </h2>
                        <h2 *ngIf="!isSuccessfulRegister">
                            {{registrationTemplate.PostRegistrationErrorSubTitle}}
                        </h2>
                        <p *ngIf="!isSuccessfulRegister">
                          {{errorMessage}}
                          <a href="{{customerServicePhoneNumberLink}}">{{registrationTemplate.CustomerServicePhoneNumber}}</a>
                        </p>
                        <p *ngIf="isSuccessfulRegister">
                          {{postRegistrationMessage}}
                        </p>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-md-12">
                          <button
                            ID="btnPostRegister"
                            class="btn btn-primary"
                            type="button"
                            (click)="postRegisterClick()">
                              {{postRegistrationButtonLabel}}

                          </button>
                      </div>
                    </div>
                </div>
              </div>
            </div>

          </div>
        </fieldset>
      </form>
    </div>
  </div>

  <div class="container justify-content-center user-footer">
      <p style="text-align: center">
        {{registrationTemplate.Footer||'if you need further assistance, please contact CalOptima Customer Service at 1-888-587-8088'}}
        <a href="{{customerServicePhoneNumberLink}}">{{registrationTemplate.CustomerServicePhoneNumber}}</a>
      </p>
      <ul class="nav justify-content-center">
        <li class="nav-item">
          <a class="nav-link" href="{{ForgotUsernameUrl}}">{{registrationTemplate.ForgotUserNameLinkLabel||'forgot username'}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="{{ForgotPasswordUrl}}">{{registrationTemplate.ForgotPasswordLinkLabel||'forgot password'}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="{{LoginUrl}}">{{registrationTemplate.LoginLinkLabel||'login'}}</a>
        </li>
      </ul>
  </div>
</div>




