import { NgModule } from '@angular/core';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';

import { RegistrationComponent } from './registration/registration.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { NoLogInGuard } from '../core/shared/no-log-in.guard';
import { MemberRegistrationComponent } from './member-registration/member-registration.component';
import { PersonalRepresentativeRegistrationComponent } from './personal-representative-registration/personal-representative-registration.component';
import { LoginComponent } from './login/login.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { LoginHelpComponent } from './login-help/login-help.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AuthGuard } from '../core/shared/auth.guard';
import { TosGuard } from '../core/shared/tos.guard';

const routes: Routes = [
  {
    path: 'registration',
    component: RegistrationComponent,
    canActivate: [NoLogInGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate:  [NoLogInGuard]
  },
  {
    path: 'passwordreset',
    component: PasswordResetComponent,
    canActivate: [NoLogInGuard]
  },
  {
    path: 'memberregistration',
    component: MemberRegistrationComponent,
    canActivate: [NoLogInGuard]
  },
  {
    path: 'personalrepresentativeregistration',
    component: PersonalRepresentativeRegistrationComponent,
    canActivate:[NoLogInGuard]
  },
  {
    path: 'forgotusername',
    component: ForgotUsernameComponent,
    canActivate: [NoLogInGuard]
  },
  {
    path: 'loginhelp',
    component: LoginHelpComponent,
    canActivate: [NoLogInGuard]
  },
  {
    path: 'userprofile',
    component: UserProfileComponent,
    canActivate: [AuthGuard, TosGuard]
  },

];

export const routedComponents = [
  RegistrationComponent
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
