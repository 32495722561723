
import { Component, OnInit, Input, forwardRef} from '@angular/core';
import { SecurityQuestionProperties } from '../../security-question-properties';
import { Configuration } from '../../../../app.constants';
import { NG_VALIDATORS, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { UpdateUserProfile } from '../../update-user-profile';
import { KBA } from '../../kba';

@Component({
  selector: 'app-security-question',
  templateUrl: './security-question.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SecurityQuestionComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SecurityQuestionComponent),
      multi: true,
    }
  ],
})

export class SecurityQuestionComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() securityQuestionProperties = new SecurityQuestionProperties();

  // Start form control interface implements
  @Input() name: string;
  public innerValue: KBA;

  onChange: any = (_: any) => { };
  onTouched: any = () => { };

  get value() {
    return this.innerValue;
  }

  set value(val) {
    if (this.innerValue !== val) {
      this.innerValue = val;
      this.onChange(val);
      // this.onTouched();
    }
  }
  //Set touched on blur
  onBlur() {
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  //set input value from caller component.
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  validate({ value }: UntypedFormControl) {
    if(this.securityQuestionProperties.Required)
    {
      if (!this.value || !this.value.SecretQuestion1 || !this.value.SecretAnswer1 || !this.value.SecretQuestion2 || !this.value.SecretAnswer2) 
      {
        return { 'required': true };
      }
    }
    return null;
  }
  // End form control interface implements

  public hideAnswersValue = true;

  constructor(private config: Configuration) { }

  ngOnInit() {
    this.innerValue = new KBA();
    this.securityQuestionProperties.Question1Values = this.config.SecurityQuestions;
    this.securityQuestionProperties.Question2Values = this.config.SecurityQuestions;
  }

  public onChangeValues(): void {
    this.value = {...this.innerValue};
  }
}
