export class PasswordResetTemplate {
    public TabTitle: string;
    public SubMessage: string;
    public UsernameLabel: string;
    public ContinueButton: string;
    public LoginLabel: string;
    public RegisterNewUserLabel: string;
    public NewPasswordLabel: string;
    public ConfirmNewPasswordLabel: string;
    public PasswordLengthMessage: string;
    public PasswordConditionsMessage: string;
    public PasswordLengthErrorMessage: string;
    public PasswordDontMatchErrorMessage: string;
    public PasswordMeetsCritieraSuccessMessage: string;
    public PasswordMatchSuccessMessage: string;
    public PasswordSuccessMessage: string;
    public TroubleLoggingInMessage: string;
    public VisitDotOrgMessage: string;
    public ReturnToMemberPortalLabel: string;
    public ApplicationTitle: string;
    public TabTitleForgotUserName: string;
    public UserNameInvalidErrorMessage: string;
    public GeneralErrorMessage: string;
    public PasswordFormatError: string;
    public PasswordMeetComplexityMessage: string;
    public PasswordMeetNumericalMessage: string;
    public PasswordMeetSymbolMessage: string;
    public PasswordMeetUpperAlphaMessage: string;
    public PasswordMeetLowerAlphaMessage: string;
    public PasswordHistoryErrorMessage: string;
    public ErrorMessageAccountLocked: string;
    public UsernameAndPasswordCannotBeTheSameError: string;
 }
