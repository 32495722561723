
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UserRoutingModule, routedComponents } from './user-routing.module';

import { CoreModule } from '../core/core.module';
import { RegistrationComponent } from './registration/registration.component';
import { UserNameComponent } from './shared/components/username/username.component';

import { SecurityQuestionComponent } from './shared/components/security-question/security-question.component';
import { PasswordComponent } from './shared/components/password/password.component';

import { MemberRegistrationComponent } from './member-registration/member-registration.component';
import { UserHeaderComponent } from './shared/components/user-header/user-header.component';
import { PersonalRepresentativeRegistrationComponent } from './personal-representative-registration/personal-representative-registration.component';
import { UserPhoneComponent } from './shared/components/user-phone/user-phone.component';
import { UserEmailComponent } from './shared/components/user-email/user-email.component';
import { MemberIdComponent } from './shared/components/member-id/member-id.component';

import { UserPhonePipe } from './shared/pipes/user-phone.pipe';
import { LoginComponent } from './login/login.component';
import { SecondFactorComponent } from './second-factor/second-factor.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { LoginHelpComponent } from './login-help/login-help.component';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PhonePipe } from '../core/shared/pipes/phone.pipe';

@NgModule({
  declarations: [
    routedComponents,
    RegistrationComponent,
    UserNameComponent,
    SecurityQuestionComponent,
    PasswordComponent,
    MemberRegistrationComponent,
    UserHeaderComponent,
    PersonalRepresentativeRegistrationComponent,
    UserEmailComponent,
    MemberIdComponent,
    LoginComponent,
    SecondFactorComponent,
    ForgotUsernameComponent,
    PasswordResetComponent,
    LoginHelpComponent,
    UserProfileComponent
  ],
  imports: [
    DialogModule,
    ConfirmDialogModule,
    FormsModule,
    CommonModule,
    UserRoutingModule,
    CoreModule
  ],
  exports: [
    UserPhonePipe,
    PhonePipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class UserModule { }
