<div class="override-input-container tab-title-intro ">
    <div *ngIf="isSelectingAuthOption">
        {{secondFactorTemplate.DeliveryMethodLabel || "please choose a delivery method for your security code"}}
    </div>

    <div *ngIf="isAnsweringQuestions && value?.status !== twoFactorStatus.ANSWER_ERROR" >
        {{secondFactorTemplate.AnsweringQuestionsHeader || "please provide answers to these questions that only you would know."}}
    </div>

    <div *ngIf="isEnteringPasscode && value?.status !== twoFactorStatus.PASSCODE_ERROR" >
        {{secondFactorTemplate.PasscodeMessage || "enter the passcode you recieved"}}
    </div>
</div>
<div class="tab-box-body">
    <div class="override-input-container">
        <div style="width:100%;">
            <form name="secondFactorForm" (ngSubmit)="btnContinue_Click()" #f="ngForm" autocomplete="off">

                <!-- isSelectingAuthOption -->
                <div *ngIf="isSelectingAuthOption">
                    <label class="radio-container" *ngFor="let factor of choices" [ngSwitch]="factor.factorType">
                        <input  type="radio" name="factorChoice" id="rdoFactor{{factor.factorType}}" 
                            [(ngModel)]="selectedFactorType" value={{factor.factorType}} (click)="onSelect(factor)" />

                        <span *ngSwitchCase="'Call'">{{secondFactorTemplate.CallLabel || "call"}} <span style="font-weight:700">{{factor.value}}</span></span>
                        <span *ngSwitchCase="'SMS'">{{secondFactorTemplate.SMSLabel || "send a text message to"}} <span style="font-weight:700">{{factor.value}}</span> </span>
                        <span *ngSwitchCase="'Email'">{{secondFactorTemplate.EmailLabel || "send an email to :"}} <span style="font-weight:700">{{factor.value}}</span></span>
                        <span class="checkmark"></span>
                    </label>
                    <label class="radio-container" *ngIf="questions.length > 0">
                        <input type="radio" [(ngModel)]="selectedFactorType" name="factorChoice" id="rdoFactorAnswerQuestions" value="Question" (click)="onSelectAnswerQuestions()" />
                        <span>{{secondFactorTemplate.AnswerQuestionsLabel || "answer these challenge questions:"}}</span>
                        <span class="checkmark"></span>
                    </label>
                </div>
                <!-- isEnteringPasscode -->
                <div *ngIf="isEnteringPasscode">
                    <div class="override-input-label" style="width:100%;">{{secondFactorTemplate.PasscodeLabel || "passcode"}}</div>
                    <input type="text" name="passcode" id="passcode" class="override-input-text"
                        placeholder="{{secondFactorTemplate.PasscodePlaceholder ||'passcode'}}" [(ngModel)]="passcode" maxlength="5" pattern="\d{5}" required />

                </div>

                <!-- isAnsweringQuestions -->
                <div *ngIf="isAnsweringQuestions">
                    <div *ngFor="let question of questions; let i = index;">    
                        <div class="override-input-label" style="width:100%;">{{question.value}}</div>
                        <input class="override-input-text" type="text" [(ngModel)]="answers[i].value" class="form-control" placeholder="{{secondFactorTemplate.AnswerPlaceholder ||'answer'}}" id="answer{{i}}"
                            name="answer{{i}}" required/>
                    </div>
                </div>

                <!-- submit -->
                <div class="override-element-container row no-glutters">
                    <!-- <div class="col-md-6 mt-2 mr-2"> -->
                    <button class="btn btn-primary col-md-5 mt-2" type="button" id="btnBackFactors" (click)="btnBack_Click()">
                        {{secondFactorTemplate.BackLabel || "back"}}</button>
                    <!-- <div class="col-md-6 mt-2 ml-2"> -->
                    <button class="btn btn-primary col-md-5 offset-md-2 mt-2 " type="submit" id="btnContinueFactors"
                        [disabled]="!f.valid">{{secondFactorTemplate.ContinueLabel || "continue"}}</button>

                </div>
            </form>
        </div>
    </div>
</div>
