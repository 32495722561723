<app-user-header
 [userHeaderProperties]="userHeaderProperties">
</app-user-header>
<!-- <div class="container"> -->
  <div class="main-wrapper">
    <div class="background-container" [ngClass]="{'bcontainer-height-small': isSmallScreenWidth && isInLastStep}">
      <div class="tab-box-container" [ngClass]="{'tcontainer-height-small': isSmallScreenWidth && isInLastStep}">
        <div class="tab-box-sub2">
          <div class="tab-box-button-container">
            <a href="{{passwordResetRelativePath}}">
              <div class="tab-button-active tbox-color">
                {{passwordResetTemplate.TabTitle || "reset password"}} 
              </div>
            </a> 
            <a href="{{forgotUserNameRelativePath}}">
              <div class="tab-button-inactive">
                {{passwordResetTemplate.TabTitleForgotUserName || "forgot username"}}
              </div>
            </a>
          </div>

          <div class="tab-box-sub">
            <div class="tab-title override-input-container"  *ngIf="isInLastStep && isSmallScreenWidth">
              {{passwordResetTemplate.TabTitle || "reset password"}}
            </div>

            <div class="tab-box-progress-container" [ngClass]="{'height-reduced': showPasswordUpdate}">
              <div class="progress-bar-wrapper" style="width:75%">
                <div class="progress-bar-inner">
                  <!-- Begin First Node-->

                  <div class="progress-bar-check-wrapper">
                    <div
                      *ngIf="isInPending1" 
                      class="progress-bar-check-blue"> 
                      <span class="fa-duotone fa-check"></span> 
                    </div>
                    <div
                      *ngIf="!isInPending1"  
                      class="progress-bar-check-gray"> 
                      <span class="fa-duotone fa-check"></span> 
                    </div>
                  </div>
                  <!-- End First Node-->

                  <!-- Begin Second Node-->
                  <div class="progress-bar-separator">
                    <div 
                      *ngIf="!isInPending2" 
                      class="progress-bar-separator-inner-gray"> 
                    </div>
                    <div 
                      *ngIf="isInPending2" 
                      class="progress-bar-separator-inner-blue"> 
                    </div>
                  </div>
          
                  <div class="progress-bar-check-wrapper">
                    <div
                      *ngIf="!isInPending2"  
                      class="progress-bar-check-gray"> 
                      <span class="fa-duotone fa-check"></span> 
                    </div>
                    <div
                      *ngIf="isInPending2" 
                      class="progress-bar-check-blue"> 
                      <span class="fa-duotone fa-check"></span> 
                    </div>
                  </div>
                  <!-- End Second Node-->

                  <!-- Begin third Node-->
                  <div class="progress-bar-separator">
                    <div 
                      *ngIf="!showPasswordUpdate" 
                      class="progress-bar-separator-inner-gray"> 
                    </div>
                    <div 
                      *ngIf="showPasswordUpdate" 
                      class="progress-bar-separator-inner-blue"> 
                    </div>
                  </div>
        
                  <div class="progress-bar-check-wrapper">
                    <div
                      *ngIf="!showPasswordUpdate"  
                      class="progress-bar-check-gray"> 
                      <span class="fa-duotone fa-check"></span> 
                    </div>
                    <div
                      *ngIf="showPasswordUpdate" 
                      class="progress-bar-check-blue"> 
                      <span class="fa-duotone fa-check"></span> 
                    </div>
                  </div>
                  <!-- End third Node-->

                  <!-- Begin fourth Node-->
                  <div class="progress-bar-separator">
                    <div 
                      *ngIf="!isInLastStep" 
                      class="progress-bar-separator-inner-gray"> 
                    </div>
                    <div 
                      *ngIf="isInLastStep" 
                      class="progress-bar-separator-inner-blue"> 
                    </div>
                  </div>
          
                  <div class="progress-bar-check-wrapper">
                    <div *ngIf="!isInLastStep" class="progress-bar-check-gray"> 
                        <span class="fa-duotone fa-check"></span> 
                    </div>
                    <div *ngIf="isInLastStep" class="progress-bar-check-blue"> 
                        <span class="fa-duotone fa-check"></span> 
                    </div>
                  </div>
    
                  <!-- End fourth Node-->

                </div>
              </div>
            </div>

            <div *ngIf="!requiresTwoFactor && !showPasswordUpdate && !isInLastStep" class="override-input-container" style="text-align: left;color:#808080;font-size:14px">
              {{passwordResetTemplate.SubMessage || "please enter your username below."}}
            </div>

            <div *ngIf="errorMessage" >
              <div class="login-msg alert alert-danger" style="padding:10px">
                <i class="fa-duotone fa-ban fa-lg" aria-hidden="true"></i>
                &nbsp;<span [innerHTML]="errorMessage"></span>
              </div>
            </div>

            <div *ngIf="isInLastStep && !isSmallScreenWidth">
              <div class="login-msg alert alert-success">
                <i class="fa-duotone fa-check fa-lg" aria-hidden="true"></i>
                &nbsp;<span [innerHTML]="successMessage"></span>
              </div>
            </div>

            <div *ngIf="isInLastStep && isSmallScreenWidth" class="override-input-container" style="text-align: left;color:#808080;font-size:14px">
              {{successMessage}}
            </div>

            <div *ngIf="!requiresTwoFactor && !showPasswordUpdate && !isInLastStep" class="tab-box-body">
            
              <form #f="ngForm" (ngSubmit)="onPasswordReset()" > 
                <div class="override-input-container">
                  <div class="override-input-label">
                    {{passwordResetTemplate.UsernameLabel || "username"}}
                  </div>
                  <div style="width:100%;">
                    <input 
                      type="text" 
                      [(ngModel)]="username" 
                      class="form-control"  
                      id="username" 
                      name="username" 
                      required/>
                  </div>
                </div>
                
                <div class="override-element-container">
                  <button 
                    type="submit" 
                    class="override-login-button" 
                    [disabled]="!f.valid" >
                    {{passwordResetTemplate.ContinueButton || "continue"}}
                  </button>
                </div>
              </form>
            </div>

            <app-second-factor 
              *ngIf="requiresTwoFactor" 
              name="challenge" 
              (ngModel)="twoFactorStatus" 
              (ngModelChange)="SecondFactorChanged($event)">
            </app-second-factor>

            <!-- BEGIN Password control-->
            <div *ngIf="showPasswordUpdate && !isInLastStep" class="tab-box-body" style="margin-top:0px">
              <app-password
                [passwordProperties]="passwordProperties"
                (emitPassword)="onPasswordChange($event)"
                [userName]="username"
                (keydown.enter)="keyDownFunction($event)">
              </app-password>

              <div class="override-element-container" style="margin-top:10px;">
                <button 
                  (click)="onPasswordUpdate()"
                  class="btn btn-primary override-login-button" 
                  [disabled]="!isValidPassword" 
                  tabindex="0">
                  {{passwordResetTemplate.ContinueButton || "continue"}}
                </button>
              </div>
            </div>
            <!-- END Password control-->

            <div *ngIf="isInLastStep" class="tab-box-body">
              <div class="override-element-container user-tab-body" >
                <div class="fa-duotone fa-check fa-5x green-check-large" style="margin-left:auto;margin-right:auto" *ngIf="isSmallScreenWidth"></div>
              </div>
              <div class="override-element-container user-tab-body" [innerHTML]="passwordResetTemplate.TroubleLoggingInMessage"></div>
              <div class="override-element-container user-tab-body" [innerHTML]="passwordResetTemplate.VisitDotOrgMessage"></div>
              <div class="override-element-container user-tab-body-bottom">
                <button class="override-login-button" (click)="redirectToLogin()">
                    {{passwordResetTemplate.ReturnToMemberPortalLabel   || "return to Member Portal"}}
                </button>
              </div>
            </div>

            <div class="tab-box-link-container override-input-container" *ngIf="!isInLastStep" [ngClass]="{'link-container-small': isInLastStep}">
              <a href="{{LoginUrl}}" tabindex="-1">
                <div class="tab-link-bottom-left" [ngClass]="{'height-reduced-byquarter': showPasswordUpdate}">
                  {{passwordResetTemplate.LoginLabel || "member login"}}
                </div>
              </a>
              <a *ngIf="showRegistrationLink" href="{{registrationPath}}">
                <div class="tab-link-bottom-right">
                  {{passwordResetTemplate.RegisterNewUserLabel || "register as new user"}}
                </div>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->
<br *ngIf="!isSmallScreenWidth">



  
