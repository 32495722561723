
<div class="row">
    <div class="col-md-6">
        <div class="form-group">
            <label for="selectQuestion1">{{securityQuestionProperties.Question1Label || 'Question'}}</label>
            <select 
                [(ngModel)]="innerValue.SecretQuestion1"
                class="form-select" 
                name="selectQuestion1" 
                id="selectQuestion1"
                tabindex="{{securityQuestionProperties.Question1TabIndex}}"
                (ngModelChange)="onChangeValues()">
                <option *ngFor="let question of securityQuestionProperties.Question1Values" 
                    [ngValue]="question"
                    [disabled]="innerValue.SecretQuestion2 === question">
                    {{question.text}}
                </option>
            </select>
        </div>
        <div class="form-group">
            <label for="inputQuestion1Answer">{{securityQuestionProperties.Question1AnswerLabel || 'Answer'}}</label>
            <input 
                type="{{!hideAnswersValue == true?'text':'password'}}" 
                class="form-control" 
                id="inputQuestion1Answer" 
                tabindex="{{securityQuestionProperties.Answer1TabIndex}}"
                [(ngModel)]="innerValue.SecretAnswer1" 
                (ngModelChange)="onChangeValues()"
                [ngClass]="{'is-invalid': answer1.invalid}"
                [required] = "securityQuestionProperties.Required"
                #answer1 = "ngModel">

                <label  *ngIf="answer1.hasError('required')" class="invalid-feedback">
                    {{securityQuestionProperties?.AnswerRequiredMessage || 'answer is required'}}
                </label>
        </div>
    </div>
    <div class="col-md-6">
        <!--Question 2-->
        <div class="form-group">
            <label for="selectQuestion2">{{securityQuestionProperties.Question2Label || 'Question'}}</label>
            <select 
                [(ngModel)]="innerValue.SecretQuestion2"
                class="form-select" 
                name="selectQuestion2" 
                id="selectQuestion2"
                tabindex="{{securityQuestionProperties.Question2TabIndex}}"
                (ngModelChange)="onChangeValues()">
                <option *ngFor="let question of securityQuestionProperties.Question2Values" 
                    [ngValue]="question"
                    [disabled]="innerValue.SecretQuestion1 === question">
                    {{question.text}}
                </option>
            </select>
        </div>
        <div class="form-group">
            <label for="inputQuestion2Answer">{{securityQuestionProperties.Question2AnswerLabel || 'Answer'}}</label>
            <input 
                type="{{!hideAnswersValue == true?'text':'password'}}" 
                class="form-control" 
                id="inputQuestion2Answer"  
                tabindex="{{securityQuestionProperties.Answer2TabIndex}}"
                [(ngModel)]="innerValue.SecretAnswer2"
                (ngModelChange)="onChangeValues()"
                [ngClass]="{'is-invalid': answer2.invalid}"
                [required] = "securityQuestionProperties.Required"
                #answer2 = "ngModel">
                <label  *ngIf="answer2.hasError('required')" class="invalid-feedback">
                    {{securityQuestionProperties?.AnswerRequiredMessage || 'answer is required'}}
                </label>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <div class="form-group">
            <div class="custom-control custom-checkbox">
                <input 
                    class="custom-control-input" 
                    type="checkbox" 
                    [(ngModel)]="hideAnswersValue" 
                    id="chkHideAnswersValue">
                <label 
                    class="custom-control-label" 
                    for="chkHideAnswersValue">
                    {{securityQuestionProperties.HideAnswersLabel || 'Hide Answers'}}
                </label>
        </div>
    </div>
</div>