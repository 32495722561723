import { Component, OnInit, Input } from '@angular/core';
import { UserHeaderProperties } from '../../user-header-properties';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent implements OnInit {

  @Input() userHeaderProperties = new UserHeaderProperties();

  constructor() { }

  ngOnInit() {
  }

}
