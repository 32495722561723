import { PersonalRepresentative } from "./personal-representative";

export class KnowledgeBaseObject {
  Question: string;
  Answer: string;
}

export class UserProfileObject {
  userName: string;
  address1: string;
  city: string;
  email: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  password: string;
  state: string;
  userID: string;
  zip: string;
  AuxID8: string; // Date
  AuxID10: string;
  MemberID: string;
  BirthDate: string; // Date
  RepresentativeAccessFlag: string;
  MemberPhone: string;
  MemberZipCode: string;
  personalRepresentative: PersonalRepresentative;
  KnowledgeBase: Array<KnowledgeBaseObject> = new Array<KnowledgeBaseObject>();
}

export class RegistrationRequest {
  UserProfile: UserProfileObject = new UserProfileObject();
  clientId: string;
}

export class MemberRegistrationCompleteRequest{
  CIN: string;
  UserName: string;
  IsPersonalRepresentative:boolean;
}
