export class MemberIdProperties {
    public MemberIdValue: string;
    public MemberIdPlaceholder: string;
    public MemberIdLabelText: string;
    public MemberIdMessage: string;
    public TabIndex: string;
    public ErrorMessage: string;
    public SampleIDCardOkLabel: string;
    public SampleMemberIDCardTitle: string;
}
