export class RegistrationTemplate {
   public CIN: string;
   public FirstName: string;
   public LastName: string;
   public MiddleInitial: string;
   public DateOfBirth: string;
   public Header: string;
   public Email: string;
   public UserName: string;
   public Password: string;
   public ConfirmPassword: string;
   public SecretQuestion1: string;
   public SecretAnswer1: string;
   public SecretQuestion2: string;
   public SecretAnswer2: string;
   public SecretQuestion3: string;
   public SecretAnswer3: string;
   public Address1: string;
   public Address2: string;
   public City: string;
   public State: string;
   public ZipCode: string;
   public Terms: string;
   public Instructions: string;
   public Phone: string;
   public Cell: string;
   public RegisterButton: string;
   public PersonalInformationLabel: string;
   public Footer: string;
   public Reset: string;
   public RegistrationTitle: string;
   public PasswordNoMatchError: string;
   public PasswordFormatError: string;
   public InvalidFormatError: string;
   public InvalidPhoneError: string;
   public InvalidEmailError: string;
   public SecurityQuestionsDropdownMessageLabel: string;
   public HideAnswersLabel: string;
   public ForgotUserNameLinkLabel: string;
   public ForgotPasswordLinkLabel: string;
   public LoginLinkLabel: string;
   public UserNameLengthMessage: string;
   public MemberIDMessage: string;
   public RequiredMessage: string;
   public PasswordMessage: string;
   public UserNameInputMessage: string;
   public MemberIDInputMessage: string;
   public PasswordInputMessage: string;
   public ConfirmPasswordInputMessage: string;
   public MobilePhoneInputMessage: string;
   public EmailAddressInputMessage: string;
   public DateOfBirthInputMessage: string;
   public MemberInformationTitle: string;
   public BackButtonLabel: string;
   public NextButtonLabel: string;
   public UserInformationTitle: string;
   public UserInformationContTitle: string;
   public SuccessMessage: string;
   public MainTitle: string;
   public SecurityQuestionsLabel: string;
   public TypeOfAccountLabel: string;
   public CalOptimaMemberOptionLabel: string;
   public PersonalRepOptionLabel: string;
   public AccountTypeSubmitButtonLabel: string;
   public PersonalRepInformationTitle: string;
   public RelationToMemberLabel: string;
   public PersonalRepMainTitle: string;
   public FirstnamePlaceholder: string;
   public LastnamePlaceholder: string;
   public ZipCodePlaceholder: string;
   public MiddleInitialPlaceholder: string;
   public UserNamePlaceholder: string;
   public UserNameAlreadyTakenMessage: string;
   public PersonalRepMemberIDMessage: string;
   public PersonalRepFirstNameLabel: string;
   public PersonalRepLastNameLabel: string;
   public PersonalRepStreetLabel: string;
   public PersonalRepCityLabel: string;
   public PersonalRepStateLabel: string;
   public PersonalRepPhoneNumberLabel: string;
   public PersonalRepDateOfBirthLabel: string;
   public PersonalRepMemberInfoDisclaimer1: string;
   public PersonalRepMemberInfoDisclaimer2: string;
   public PersonalRepZipCodeLabel: string;
   public PersonalRepZipCodePlaceholder: string;
   public MemberIsOver18ErrorMessage: string;
   public PersonalRepIsUnder18ErrorMessage: string;
   public PersonalRepUserNameMessage: string;
   public EmailAddressUserMessage: string;
   public UserInformationPhoneLabel: string;
   public AttestationTitle: string;
   public AttestationMessage: string;
   public AttestationAgreeLabel: string;
   public PostRegistrationTitle: string;
   public PostRegistrationAlertErrorMessage: string;
   public PostRegistrationAlertSuccessMessage: string;
   public PostRegistrationSuccessSubTitle: string;
   public PostRegistrationErrorSubTitle: string;
   public PostRegistrationSuccessMessage: string;
   public PostRegistrationSuccessReviewMessage: string;
   public PostRegistrationButtonDotOrgLabel: string;
   public PostRegistrationButtonLoginLabel: string;
   public CustomerServicePhoneNumber: string;
   public PostRegistrationErrorMessage: string;
   public PersonalRepRepInfoDisclaimer: string;
   public PersonalRepSecurityQuestionsDisclaimer: string;
   public PersonalRepUserInfoDisclaimer: string;
   public PhoneRequiredMessage: string;
   public ErrorCode_CININC: string;
   public ErrorCode_USRSTG: string;
   public ErrorCode_USRNMM: string;
   public ErrorCode_USRUTB: string;
   public ErrorCode_DOBINC: string;
   public ErrorCode_USRXTB: string;
   public ErrorCode_USRUSE: string;
   public ErrorCode_NOTELG: string;
   public ErrorCode_CINUSR: string;
   public ErrorCode_USRMIN: string;
   public ErrorCode_USR13M: string;
   public ErrorCode_WARN14: string;
   public Relationship_CAL2: string;
   public Relationship_CAL3: string;
   public Relationship_CAL5: string;
   public Relationship_CAL6: string;
   public Relationship_CAL9: string;
   public UsernameAndPasswordCannotBeTheSameError: string;
   public Question1: string;
   public Question2: string;
   public Question3: string;
   public Question4: string;
   public Question5: string;
   public Question6: string;
   public Question7: string;
   public Question8: string;
   public Question9: string;
   public Question10: string;
   public AcceptanceCriteria: string;
   public SampleMemberIDCardTitle: string;
   public SampleIDCardOkLabel: string;
}
