export class SecondFactorTemplate{
    public DeliveryMethodLabel: string;
    public CallLabel: string;
    public SMSLabel: string;
    public EmailLabel: string;
    public AnswerQuestionsLabel: string;
    public AnsweringQuestionsHeader: string;
    public PasscodeLabel: string;
    public PasscodePlaceholder: string;
    public AnswerPlaceholder: string;
    public ContinueLabel: string;
    public BackLabel: string;
    public PasscodeIncorrectError: string;
    public AnswersIncorrectError: string;
}