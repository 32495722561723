export class LoginTemplate {
    public MainTitle: string;
    public SubMessage: string;
    public UsernameLabel: string;
    public ForgotUsernameLabel: string;
    public PasswordLabel: string;
    public ForgotPasswordLabel: string;
    public RememberMeLabel: string;
    public LoginButtonLabel: string;
    public RegisterNewUserLabel: string;
    public LoginHelpLabel: string;
    public UsernamePlaceholder: string;
    public PasswordPlaceholder: string;
    public ApplicationTitle: string;
    public PasscodeMessage: string;
    public ErrorMessageDefault: string;
    public PasswordResetHeader: string;
    public PasswordResetIntro: string;
    public NewPasswordLabel: string;
    public ContinueButtonLabel: string;
    public SubmitButtonLabel: string;
    public LoginLinkLabel: string;
    public ErrorMessageAccountLocked: string;
    public ErrorMessageTempPasswordInvalid: string;
 }
