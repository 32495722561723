import { Component, OnInit, forwardRef, Input, OnDestroy } from '@angular/core';
import { NG_VALIDATORS, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { SecondFactorTemplate } from '../shared/second-factor-template';
import { SecureAuthService } from '../shared/services/user.service';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { Subscription } from 'rxjs';
import { AuthenticationService, AuthenticationFactor, AuthenticationServiceStatus, AuthHelper } from '@caloptima/authentication';
import { TwoFactorStatus } from '../../member-portal/shared/enum';
import { TwoFactorInfo } from '../shared/twofactor-info';

@Component({
  selector: 'app-second-factor',
  templateUrl: './second-factor.component.html',
  styleUrls: ['./second-factor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SecondFactorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SecondFactorComponent),
      multi: true,
    }
  ],
})
export class SecondFactorComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  private subscription: Subscription;
  public errorMessage: string;
  public selectedFactor: AuthenticationFactor;
  public selectedFactorType: string;
  public passcode: string;
  public isSelectingAuthOption: boolean = true;
  public isEnteringPasscode: boolean = false;
  public isAnsweringQuestions: boolean = false;
  public secondFactorTemplate: SecondFactorTemplate = new SecondFactorTemplate();
  public choices: AuthenticationFactor[];
  public questions: AuthenticationFactor[];
  public answers: AuthenticationFactor[];
  public twoFactorStatus = TwoFactorStatus;


  // Form control implements
  @Input()
  name: string;
  @Input('value')
  val: TwoFactorInfo;

  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this.val;
  }

  set value(val) {
    if (this.val !== val)
    {
      this.val = val;
      this.onChange(val);
      this.onTouched();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  validate({ value }: UntypedFormControl) {
    const isNotValid = this.value && (this.value.status !== TwoFactorStatus.SUCCESS);
    return isNotValid && {
      invalid: true
    }
  }

  constructor(
    private authService: AuthenticationService,
    private siteCoreService: SecureAuthService,
    private languageService: LanguageService
    ) { }

  ngOnInit() {
    try {
      this.questions = [];
      this.choices = [];
      this.answers = [];
      this.authService.getFactors().subscribe(response  => {
        response.forEach(f =>{
          if(f.factorType === "Question"){
            this.questions.push(f);
            let a = new AuthenticationFactor();
            a.factorType = f.factorType;
            a.factorID = f.factorID;
            a.value = "";
            this.answers.push(a);
          }
          if (f.factorType === "Email") {
            this.selectedFactor = f;
            this.selectedFactorType = f.factorType;
            this.choices.push(f);
          }
          else if ( f.factorType === "SMS" || f.factorType === "Call" )
              {
                var _temp = f.value.replace('(','').replace(') ','-');
                f.value = _temp;
                this.choices.push(f);
              }
          else
              this.choices.push(f);

        });
      },
        error => {
          this.errorMessage = <any>error;
        });

      this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if (change.propName === 'languageCode') {
          this.loadView();
        }
      });
    } catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private loadView() {
    this.siteCoreService.getSecondFactorTemplate().subscribe(
      response => {
        this.secondFactorTemplate = response;
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }
  public btnBack_Click() {
    if (this.isAnsweringQuestions || this.isEnteringPasscode) {
      this.authService.getFactors().subscribe(response  => {
        this.isSelectingAuthOption = true;
        this.isAnsweringQuestions = false;
        this.isEnteringPasscode = false;
        this.value = { status: TwoFactorStatus.PENDING_1 };

      },
        error => {
          this.errorMessage = <any>error;
          this.value = { status: TwoFactorStatus.START };
        }
      );

    }
    else if (this.isSelectingAuthOption) {
      this.value = { status: TwoFactorStatus.START };
    }
  }

  public btnContinue_Click() {

    if (this.isSelectingAuthOption === true) {
      if (!this.selectedFactor) {
        return;
      }
      this.value = { status: TwoFactorStatus.PENDING_2 };
      if (this.selectedFactor.factorType === 'Question') {
        this.isAnsweringQuestions = true;
        this.isEnteringPasscode = false;
        this.isSelectingAuthOption = false;
      }
      else {
        // clear masked email message in value - passcode carried here
        this.selectedFactor.value = ''
        let factors = [this.selectedFactor];
        this.authService.authenticateFactors(factors).subscribe((resp) => {
          if (resp.status == AuthenticationServiceStatus.OnetimePasscodeRequired) {
            this.isAnsweringQuestions = false;
            this.isEnteringPasscode = true;
            this.isSelectingAuthOption = false;
            this.value = { status: TwoFactorStatus.PENDING_2 };
          }
          else {
            this.value = { status: TwoFactorStatus.ERROR };
          }
        },
          error => {
            this.value = { status: TwoFactorStatus.ERROR };
            this.errorMessage = <any>error;
          }
        );
      }
    }
    else if (this.isEnteringPasscode === true) {
      let answerFacts: AuthenticationFactor[] = [];

      let f = new AuthenticationFactor();
      f.factorType = this.selectedFactor.factorType;
      f.factorID = this.selectedFactor.factorID;
      f.value = this.passcode;
      answerFacts.push(f);

      this.authService.authenticateFactors(answerFacts).subscribe((resp) => {
        if (resp.status === AuthenticationServiceStatus.NoError) {
          this.value = { status: TwoFactorStatus.SUCCESS };
        }
        else {
          this.value = { status: TwoFactorStatus.PASSCODE_ERROR, message: this.secondFactorTemplate.PasscodeIncorrectError};
        }
      }
        ,
        error => {
          if (error.error == "Otp is invalid. OTP") {
            this.value = { status: TwoFactorStatus.PASSCODE_ERROR, message: this.secondFactorTemplate.PasscodeIncorrectError};
          }
          else {
            this.value = { status: TwoFactorStatus.ERROR};
            this.errorMessage = <any>error;
          }
        }
      );
    }
    else if (this.isAnsweringQuestions === true) {
      this.authService.authenticateFactors(this.answers).subscribe((resp) => {
        if (resp.status === AuthenticationServiceStatus.NoError) {
          this.value = { status: TwoFactorStatus.SUCCESS};
        }
        else {
          this.value = { status: TwoFactorStatus.ANSWER_ERROR, message: this.secondFactorTemplate.AnswersIncorrectError};
        }
      },
        error => {
          this.value = { status: TwoFactorStatus.ANSWER_ERROR, message: this.secondFactorTemplate.AnswersIncorrectError};
          this.errorMessage = <any>error;
        }
      );
    }
  }

  public onSelect(factor) {
    this.selectedFactor = factor;
  }
  public onSelectAnswerQuestions(){
      this.selectedFactor = {
        factorType: "Question",
        value: "",
        factorID: ""
    };
  }
}
