export class UserProfileTemplate {

    public UserProfileTitle: string;
    public UserProfileInfo: string;
    public ContactAndSecurityLabel: string;
    public MemberDetailsLabel: string;
    public AccountRecoveryMethodsLabel: string;
    public SecurityQuestionsLabel: string;
    public UserIDLabel: string;

    public ContactPhoneLabel: string;
    public UpdateMemberProfileText: string;
    public MobilePhoneRequiredMessage: string;
    public MobilePhoneInvalidInputMessage: string;

    public EmailLabel: string;
    public InvalidEmailFormatMessage: string;
    public EmailRequiredErrorMessage: string;
   
    public HideAnswersLabel: string;
    public SecretQuestion1: string;
    public SecretAnswer1: string;
    public SecretQuestion2: string;
    public SecretAnswer2: string;
    public SecurityQuestionsDropdownMessageLabel: string;

    public Question1: string;
    public Question2: string;
    public Question3: string;
    public Question4: string;
    public Question5: string;
    public Question6: string;
    public Question7: string;
    public Question8: string;
    public Question9: string;
    public Question10: string;

    public ValidationErrorMessage: string;
    public SuccessMessage: string;
    public ErrorMessage: string;
    public ConfirmFormResetMessage: string;
    public ConfirmFormResetHeader: string;
    public No: string;
    public Yes: string;
    public CancelLabel: string;
    public FinishLabel: string;
    public AnswerRequiredMessage: string;

    //Update password
    public UpdatePasswordLabel: string;
    public ResetPasswordLabel: string;
    public UpdatePasswordButton: string;
    public UpdatePasswordSuccess: string;
    public UpdatePasswordError: string;
    public NewPasswordLabel: string;
    public ConfirmNewPasswordLabel: string;
    public PasswordMeetsCritieraSuccessMessage: string;
    public PasswordFormatError: string;
    public PasswordLengthErrorMessage: string;
    public UsernameAndPasswordCannotBeTheSameError: string;
    public PasswordMatchSuccessMessage: string;
    public PasswordDontMatchErrorMessage: string;

    public PasswordLengthMessage: string;
    public PasswordMeetNumericalMessage: string;
    public PasswordMeetSymbolMessage: string;
    public PasswordMeetUpperAlphaMessage: string;
    public PasswordMeetLowerAlphaMessage: string;
    public PasswordMeetComplexityMessage: string;

 }
