export class PasswordProperties {
    public PasswordValue: string;
    public PasswordLabelText: string;
    public PasswordMessage: string;
    public PasswordPlaceholder: string;
    public ConfirmPasswordValue: string;
    public ConfirmPasswordLabelText: string;
    public ConfirmPasswordPlaceholder: string;
    public PasswordNoMatchError: string;
    public PasswordFormatError: string;
    public ErrorMessage: string;
    public PasswordTabIndex: string;
    public ConfirmPasswordTabIndex: string;
    public LabelStyle: string;
    public InputContainerRowStyle: string;
    public InputContainerStyle: string;

    public InputStyle: string;
    public AcceptanceCriteriaLabel: string;
    public AcceptanceCriteriaContainerStyle: string;
    public PasswordMeetsCritieraSuccessMessage: string;
    public PasswordLengthErrorMessage: string;
    public UsernameAndPasswordCannotBeTheSameError: string;
    public PasswordMatchSuccessMessage: string;
    public PasswordLengthMessage: string;
    public PasswordMeetComplexityMessage: string;

    public PasswordMeetNumericalMessage: string;
    public PasswordMeetSymbolMessage: string;
    public PasswordMeetUpperAlphaMessage: string;
    public PasswordMeetLowerAlphaMessage: string;
    public ShowGreyCircle: boolean;
}
