export class ForgotUserNameTemplate {
    public TabTitle: string;
    public SubMessage: string;
    public MemberIDLabel: string;
    public ContinueButton: string;
    public MemberLoginLabel: string;
    public RegisterAsNewUserLabel: string;
    public ForgotUsernameMessage: string;
    public HereIsYourUsernameLabel: string;
    public TroubleLoggingInMessage: string;
    public VisitDotOrgMessage: string;
    public ReturnToMemberPortalLabel: string;
    public ApplicationTitle: string;
    public TabTitleResetPassword: string;
    public ErrorMessageDefault: string;
    public ErrorMessageGeneral: string;
    public ErrorMessageAccountLocked: string;
 }
