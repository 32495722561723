import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SecureAuthService } from '../shared/services/user.service';
import { Configuration } from '../../app.constants';
import { RegistrationTemplate } from '../shared/registration-template';
import { UserNameProperties } from '../shared/username-properties';
import { PasswordProperties } from '../shared/password-properties';
import { DateOfBirthProperties } from '../../core/shared/date-of-birth-properties';
import { Registration } from '../shared/registration';
import { SecurityQuestionProperties } from '../shared/security-question-properties';
import { UserHeaderProperties } from '../shared/user-header-properties';
import { MemberIdProperties } from '../shared/memberid-properties';
import { PhoneProperties } from '../shared/phone-properties';
import { EmailProperties } from '../shared/email-properties';
import { Subscription } from 'rxjs';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { CommonService } from '../../core/shared/services/common.service';
import { PasswordResetTemplate } from '../shared/passwordreset-template';
import { KBA } from '../shared/kba';

@Component({
  selector: 'app-member-registration',
  templateUrl: './member-registration.component.html',
  providers: [CommonService],
  styleUrls: ['./member-registration.component.scss']
})
export class MemberRegistrationComponent implements OnInit, OnDestroy {

  public userNameProperties = new UserNameProperties();
  public passwordProperties = new PasswordProperties();
  public dateOfBirthProperties = new DateOfBirthProperties();
  public securityQuestionProperties = new SecurityQuestionProperties();
  public userHeaderProperties = new UserHeaderProperties();
  public memberIdProperties = new MemberIdProperties();
  public phoneProperties = new PhoneProperties();
  public emailProperties = new EmailProperties();
  public registrationTemplate = new RegistrationTemplate();
  public passwordResetTemplate: PasswordResetTemplate = new PasswordResetTemplate();
  public kba = new KBA();

  public ForgotUsernameUrl: string;
  public ForgotPasswordUrl: string;
  public LoginUrl: string;
  public MainRegistrationUrl: string;
  public customerServicePhoneNumberLink: string;

  public registration = new Registration();
  public errorMessage: string;
  public isDobValid: boolean;
  public isValidPasswordFormat: boolean;
  public isPasswordMatch: boolean;
  public isValidPhoneFormat: boolean;
  public isValidEmail: boolean;
  public isValidMemberId: boolean;
  public isSuccessfulRegister: boolean;
  public isAvailableUserName: boolean;
  public isValidUserName: boolean;
  public isValidUserNamePassword: boolean;
  public delayTimeToLogin: number;
  public hasRegistrationOccurred: boolean;
  public controlTabIndex: number = 1;

  // public step1BackTabIndex: number;
  public step1ForwardTabIndex: number;
  public step2ForwardTabIndex: number;
  public step3ForwardTabIndex: number;
  public step4ForwardTabIndex: number;

  public fnValidateFirstStep: Function;
  public fnValidateSecondStep: Function;
  public fnValidateThirdStep: Function;
  private subscription: Subscription;

  public isStepOneSubmit: boolean = false;
  public isStepTwoSubmit: boolean = false;
  public isStepThreeSubmit: boolean = false;

  public stepNumber: number = 1;

  constructor(
    private userService: SecureAuthService,
    public config: Configuration,
    private languageService: LanguageService,
    private commonService: CommonService
    ) { }

  ngOnInit() {
    this.hasRegistrationOccurred = false;

    this.delayTimeToLogin = 5000;
    this.ForgotUsernameUrl = this.config.ForgotUsernameUrl;
    this.ForgotPasswordUrl = this.config.PasswordResetUrl;
    this.LoginUrl = this.config.LoginUrl;
    this.MainRegistrationUrl = this.config.MainRegistrationUrl;

    try {
      this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if (change.propName === 'languageCode') {
          this.loadView();
        }
      });
    } catch (ex) {
      throw ex;
    }
     this.SetTabIndex();

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  private loadView() {

     this.userService.getRegistrationContent().subscribe(
      response => {
        this.registrationTemplate = response;
        this.customerServicePhoneNumberLink = 'tel:' + this.registrationTemplate.CustomerServicePhoneNumber;

        this.loadDateOfBirthView();
        this.loadSecurityQuestionsView();
        this.loadUserNameView();
        this.loadUserHeaderView();
        this.loadMemberIdView();
        this.loadPhoneView();
        this.loadEmailView();

        this.userService.getPasswordResetTemplate().subscribe(
          responsePR => {
            this.passwordResetTemplate = responsePR;
            this.loadPasswordView();
          },
          error => {
            this.errorMessage = <any>error;
          });
        },
      error => {
        this.errorMessage = <any>error;
      });
  }

  returnNewTabIndex(): number {
    return this.controlTabIndex++;
  }
  private SetTabIndex(): void {

    this.memberIdProperties.TabIndex = String(this.returnNewTabIndex());
    this.dateOfBirthProperties.TabIndexMonth = String(this.returnNewTabIndex());
    this.dateOfBirthProperties.TabIndexDay = String(this.returnNewTabIndex());
    this.dateOfBirthProperties.TabIndexYear = String(this.returnNewTabIndex());
    this.step1ForwardTabIndex = this.returnNewTabIndex();

    this.userNameProperties.TabIndex = String(this.returnNewTabIndex());
    this.passwordProperties.PasswordTabIndex = String(this.returnNewTabIndex());
    this.passwordProperties.ConfirmPasswordTabIndex = String(this.returnNewTabIndex());
    this.step2ForwardTabIndex = this.returnNewTabIndex();

    this.phoneProperties.TabIndex = String(this.returnNewTabIndex());
    this.emailProperties.TabIndex = String(this.returnNewTabIndex());
    this.step3ForwardTabIndex = this.returnNewTabIndex();

    this.securityQuestionProperties.Question1TabIndex = String(this.returnNewTabIndex());
    this.securityQuestionProperties.Answer1TabIndex = String(this.returnNewTabIndex());
    this.securityQuestionProperties.Question2TabIndex = String(this.returnNewTabIndex());
    this.securityQuestionProperties.Answer2TabIndex = String(this.returnNewTabIndex());
    this.step4ForwardTabIndex = this.returnNewTabIndex();
  }

  private loadEmailView(): void {
    this.emailProperties.EmailPlaceholder = this.registrationTemplate.EmailAddressInputMessage;
    this.emailProperties.EmailLabelText = this.registrationTemplate.Email;
    this.emailProperties.ErrorMessage = this.registrationTemplate.InvalidEmailError;
    this.emailProperties.Message = this.registrationTemplate.EmailAddressUserMessage;
  }

  private loadPhoneView(): void {
    this.phoneProperties.Placeholder = this.registrationTemplate.MobilePhoneInputMessage;
    this.phoneProperties.LabelText = this.registrationTemplate.UserInformationPhoneLabel;
    this.phoneProperties.ErrorMessage = this.registrationTemplate.InvalidPhoneError;
    this.phoneProperties.RequiredMessage = this.registrationTemplate.PhoneRequiredMessage;
    this.phoneProperties.Name = 'memberPhone';
    this.phoneProperties.IsRequired = false;
  }

  private loadMemberIdView(): void {
    this.memberIdProperties.MemberIdLabelText = this.registrationTemplate.CIN;
    this.memberIdProperties.ErrorMessage = this.registrationTemplate.InvalidFormatError;
    this.memberIdProperties.MemberIdPlaceholder = this.registrationTemplate.MemberIDInputMessage;
    this.memberIdProperties.MemberIdMessage = this.registrationTemplate.MemberIDMessage;
    this.memberIdProperties.SampleMemberIDCardTitle = this.registrationTemplate.SampleMemberIDCardTitle;
    this.memberIdProperties.SampleIDCardOkLabel = this.registrationTemplate.SampleIDCardOkLabel;
  }

  private loadUserHeaderView(): void {
    this.userHeaderProperties.MainTitle = this.registrationTemplate.MainTitle;
  }
  private loadSecurityQuestionsView(): void {
    this.securityQuestionProperties.HideAnswersLabel = this.registrationTemplate.HideAnswersLabel;
    this.securityQuestionProperties.Question1Label = this.registrationTemplate.SecretQuestion1;
    this.securityQuestionProperties.Question1AnswerLabel = this.registrationTemplate.SecretAnswer1;
    this.securityQuestionProperties.Question2Label = this.registrationTemplate.SecretQuestion2;
    this.securityQuestionProperties.Question2AnswerLabel = this.registrationTemplate.SecretAnswer2;
    this.securityQuestionProperties.SecurityQuestionsDropdownMessageLabel = this.registrationTemplate.SecurityQuestionsDropdownMessageLabel;
    this.config.UpdateSecurityQuestions(this.registrationTemplate);
    this.securityQuestionProperties.Question1Values = this.config.SecurityQuestions;
    this.securityQuestionProperties.Question2Values = this.config.SecurityQuestions;
    this.kba.SecretQuestion1 = this.config.SecurityQuestions[0];
    this.kba.SecretQuestion2 = this.config.SecurityQuestions[1];
  }

  private loadPasswordView(): void {
    this.passwordProperties.PasswordLabelText = this.registrationTemplate.Password;
    this.passwordProperties.ConfirmPasswordLabelText = this.registrationTemplate.ConfirmPassword;
    this.passwordProperties.PasswordFormatError = this.registrationTemplate.PasswordFormatError;
    this.passwordProperties.PasswordLengthErrorMessage = this.passwordResetTemplate.PasswordLengthErrorMessage;
    this.passwordProperties.UsernameAndPasswordCannotBeTheSameError = this.passwordResetTemplate.UsernameAndPasswordCannotBeTheSameError;
    this.passwordProperties.PasswordMatchSuccessMessage = this.passwordResetTemplate.PasswordMatchSuccessMessage;
    this.passwordProperties.PasswordNoMatchError = this.registrationTemplate.PasswordNoMatchError;
    this.passwordProperties.PasswordLengthMessage = this.passwordResetTemplate.PasswordLengthMessage;
    this.passwordProperties.PasswordMeetComplexityMessage = this.passwordResetTemplate.PasswordMeetComplexityMessage;
    this.passwordProperties.PasswordMeetsCritieraSuccessMessage = this.passwordResetTemplate.PasswordMeetsCritieraSuccessMessage ;
    this.passwordProperties.PasswordMeetNumericalMessage = this.passwordResetTemplate.PasswordMeetNumericalMessage;
    this.passwordProperties.PasswordMeetSymbolMessage = this.passwordResetTemplate.PasswordMeetSymbolMessage;
    this.passwordProperties.PasswordMeetUpperAlphaMessage = this.passwordResetTemplate.PasswordMeetUpperAlphaMessage;
    this.passwordProperties.PasswordMeetLowerAlphaMessage = this.passwordResetTemplate.PasswordMeetLowerAlphaMessage;

    this.passwordProperties.PasswordPlaceholder = this.registrationTemplate.PasswordInputMessage;
    this.passwordProperties.ConfirmPasswordPlaceholder = this.registrationTemplate.ConfirmPasswordInputMessage;

    this.passwordProperties.LabelStyle = 'col-form-label';
    this.passwordProperties.InputContainerStyle = '';
    this.passwordProperties.InputStyle = 'form-control';
    this.passwordProperties.AcceptanceCriteriaLabel = this.registrationTemplate.AcceptanceCriteria;
    this.passwordProperties.AcceptanceCriteriaContainerStyle = '';
    this.passwordProperties.ShowGreyCircle = true;
  }

  private loadDateOfBirthView(): void {
    this.dateOfBirthProperties.LabelText = this.registrationTemplate.DateOfBirth;
    this.dateOfBirthProperties.ErrorMessage = this.registrationTemplate.InvalidFormatError;
    this.dateOfBirthProperties.Placeholder = this.registrationTemplate.DateOfBirthInputMessage;
    this.dateOfBirthProperties.IsCorrectRange = (obj) => {
      return true;
    };
    this.dateOfBirthProperties.MonthName = 'memberDobMonth';
    this.dateOfBirthProperties.DayName = 'memberDobDay';
    this.dateOfBirthProperties.YearName = 'memberDobYear';
    this.dateOfBirthProperties.ErrorMsgName = 'memberDobErrorMsg';
  }

  private loadUserNameView(): void {
    this.userNameProperties.LabelText = this.registrationTemplate.UserName;
    this.userNameProperties.UserNamePlaceholder = this.registrationTemplate.UserNameInputMessage;
    this.userNameProperties.UserNameMessage = this.registrationTemplate.UserNameLengthMessage;
    this.userNameProperties.ErrorMessage = this.registrationTemplate.InvalidFormatError;
    this.userNameProperties.UserNameAlreadyTakenMessage = this.registrationTemplate.UserNameAlreadyTakenMessage;
    this.userNameProperties.UsernameAndPasswordSameMessage = this.registrationTemplate.UsernameAndPasswordCannotBeTheSameError;
  }

  public dateOfBirthChanged($event): void {
    this.registration.DateOfBirth = $event.dateOfBirthValue;
    this.isDobValid = $event.isValid;
  }

  public onPasswordChange($event): void {
    this.registration.Password = $event.password;
    this.registration.ConfirmPassword = $event.confirmPassword;
    this.isValidPasswordFormat = $event.isValid;
    this.isValidUserNamePassword = this.commonService.IsValidUserNamePasswordCombo(this.registration.UserName, this.registration.Password);
  }

  public onUserNameChange($event): void {
    this.registration.UserName = $event.userName;
    this.isAvailableUserName = $event.isAvailable;
    this.isValidUserName = $event.isValid;
    this.isValidUserNamePassword = this.commonService.IsValidUserNamePasswordCombo(this.registration.UserName, this.registration.Password);
  }

  public onMemberIdChange($event): void {
    this.registration.CIN = $event.memberIdValue;
    this.isValidMemberId = $event.isValid;
  }

  public onPhoneChange($event): void {
    this.registration.Phone = $event.phoneValue;
    this.isValidPhoneFormat = $event.isValid;
  }

  public onEmailChange($event): void {
    this.registration.Email = $event.emailValue;
    this.isValidEmail = $event.isValid;
  }

  public onSecurityQuestionChange($event): void {
    if ($event.SecretQuestion1 !== undefined &&
        $event.SecretQuestion2 !== undefined) {
        this.registration.SecretAnswer1 = $event.SecretAnswer1;
        this.registration.SecretQuestion1 = $event.SecretQuestion1.value;

        this.registration.SecretAnswer2 = $event.SecretAnswer2;
        this.registration.SecretQuestion2 = $event.SecretQuestion2.value;
    }
  }

  public redirectToMainRegistration(): void {
    window.location.href = this.MainRegistrationUrl;
  }

  public redirectToLogin(): void {
    window.location.href = this.LoginUrl;
  }

  public validateFirstStep($event): void {
    this.isStepOneSubmit = true;

    if ( this.isValidMemberId
        && this.isDobValid) {
      this.stepNumber = 2;
    }
  }

  public validateSecondStep($event): void {
    this.isStepTwoSubmit = true;

    if (this.isValidPasswordFormat
        && this.isValidUserName
        && this.isAvailableUserName
        && this.isValidUserNamePassword) {
      this.stepNumber = 3;
    }
  }

  public validateThirdStep($event): void {
    let isValidStep: boolean = false;
    this.isStepThreeSubmit = true;

    if (this.registration.Phone === undefined
      || this.registration.Phone === '') {
        this.isValidPhoneFormat = true;
      }

    if (this.isValidPhoneFormat
        && this.isValidEmail) {
      this.stepNumber = 4;
    }
  }

  public enterFinalStep(): void {
      setTimeout(() => {
        if (this.isSuccessfulRegister) {
          this.redirectToLogin();
        }
      }, this.delayTimeToLogin);
  }

  public validateRegister(): void {
    //Restricting test user email in PROD
    const testEmail = this.registration.Email.toLowerCase();

    if (
      testEmail.endsWith('@caloptima.org')
      && this.config.Environment != Configuration.ENV.DEV
      && this.config.Environment != Configuration.ENV.UAT
      && (
        testEmail != 'membersportalscrumteam@caloptima.org'
        || !this.registration.CIN.toLowerCase().startsWith('default')
      )
    ) {
      this.hasRegistrationOccurred = true;
      this.isSuccessfulRegister = false;
      this.errorMessage = 'Registration is not allowed.';
      return;
    }
    //End restriction

    if (!this.hasRegistrationOccurred) {
    this.userService.registerUser(this.registration).subscribe(
      response => {
        this.stepNumber = 5;
        this.isSuccessfulRegister = true;
        this.hasRegistrationOccurred = true;
        this.memberRegistrationComplete();
      },
      error => {
        this.stepNumber = 5;
        this.hasRegistrationOccurred = true;
        this.isSuccessfulRegister = false;
        this.showErrorMessage(error);
      });
    } else {

      this.userService.updateRegisterUser(this.registration).subscribe(
        response => {
          this.stepNumber = 5;
          this.isSuccessfulRegister = true;
        },
        errorUpdate => {
          this.stepNumber = 5;
          this.showErrorMessage(errorUpdate);
          this.isSuccessfulRegister = false;
        }
      );
    }
  }

  public showErrorMessage(error: any): void {
    let errorText: string;
    if(!this.isSuccessfulRegister && error && error.error && error.error.message){
      errorText = error.error.message;
      this.errorMessage = this.translateErrorText(errorText);
    }
    else
    {
      //TODO: Add sitecore content and multi-languages
      this.errorMessage = "We're sorry! Your registration did not work. Please try again. If you run into this issue again, please get in touch with Customer Service.";
    }
  }

  private translateErrorText(errorText:string): string
  {
    if(!errorText) return '';

    if(errorText.includes('CININC')) return this.registrationTemplate.ErrorCode_CININC;
    if(errorText.includes('USRSTG')) return this.registrationTemplate.ErrorCode_USRSTG;
    if(errorText.includes('USRNMM')) return this.registrationTemplate.ErrorCode_USRNMM;
    if(errorText.includes('USRUTB')) return this.registrationTemplate.ErrorCode_USRUTB;
    if(errorText.includes('DOBINC')) return this.registrationTemplate.ErrorCode_DOBINC;
    if(errorText.includes('USRXTB')) return this.registrationTemplate.ErrorCode_USRXTB;
    if(errorText.includes('USRUSE')) return this.registrationTemplate.ErrorCode_USRUSE;
    if(errorText.includes('NOTELG')) return this.registrationTemplate.ErrorCode_NOTELG;
    if(errorText.includes('CINUSR')) return this.registrationTemplate.ErrorCode_CINUSR;
    if(errorText.includes('USRMIN')) return this.registrationTemplate.ErrorCode_USRMIN;
    if(errorText.includes('USR13M')) return this.registrationTemplate.ErrorCode_USR13M;
    return errorText;
  }
  public memberRegistrationComplete(): void {
    this.userService.memberRegistrationComplete(this.registration).subscribe();
  }

  public goBackToStepOne(): void {
    this.stepNumber = 1;
  }

  public goBackToStepTwo(): void {
    this.stepNumber = 2;
  }

  public goBackToStepThree(): void {
    this.stepNumber = 3;
  }

}

