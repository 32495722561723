import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { EmailProperties } from "../../email-properties";

@Component({
  selector: "app-user-email",
  templateUrl: "./user-email.component.html",
  styleUrls: ["./user-email.component.scss"],
})
export class UserEmailComponent implements OnInit {
  constructor() {}

  private isValidEmail: boolean;

  @Input() emailProperties = new EmailProperties();
  @Output() emitEmail = new EventEmitter();

  ngOnInit() {}

  public onEmailChange(isEmailInValid: boolean) {
    if (isEmailInValid) {
      this.isValidEmail = false;
    } else {
      this.isValidEmail = true;
    }

    this.emitEmail.emit({
      emailValue: this.emailProperties.EmailValue,
      isValid: this.isValidEmail,
    });
  }
}
