<p-dialog [(visible)]="display"  [modal]="true" [baseZIndex]="10000" styleClass="modal-dialog-md"
[draggable]="false" [resizable]="false" [focusOnShow]="false">
  <p-header>
      {{memberIdProperties.SampleMemberIDCardTitle || "sample member ID card"}} 
  </p-header>
  <div style="text-align: center;  margin-left: 15px;margin-right: 15px;">
    <img src="../../../../../assets/images/samplecard.png">
  </div>
  <p-footer>
    <button ID="SampleIDBtn" (click)="display=false"class="btn btn-primary" 
      style="padding-left:20px;padding-right:20px;height:50px;width:80px;">
      {{memberIdProperties.SampleIDCardOkLabel || "ok"}} 
    </button>
  </p-footer>
</p-dialog>

<div class="form-group"
    [ngClass]="{'is-invalid': MemberIdValue.invalid && (MemberIdValue.touched || MemberIdValue.dirty)}">
  <label class="col-form-label" for="MemberIdValue">{{memberIdProperties.MemberIdLabelText || "member id"}}</label>
  &nbsp;
  <a *ngIf="memberIdProperties.MemberIdMessage === ''" (click)="showCard()" style="cursor: pointer">
    <i class="far fa-question-circle"aria-hidden="true"></i>
  </a>
  <div *ngIf="memberIdProperties.MemberIdMessage">
    <span class="input-message">
      {{memberIdProperties.MemberIdMessage}}
    </span>&nbsp;
      <a (click)="showCard()" style="cursor: pointer">
        <i class="far fa-question-circle"aria-hidden="true"></i>
      </a>
  </div>
  <input 
    type="text"
    class="form-control"
    id="MemberIdValue"
    name="MemberIdValue"
    #MemberIdValue="ngModel"
    tabindex="{{memberIdProperties.TabIndex}}"
    placeholder="{{memberIdProperties.MemberIdPlaceholder ||'enter member id'}}"
    [ngClass]="{'is-invalid': MemberIdValue.invalid && MemberIdValue.touched && MemberIdValue.dirty}"
    [(ngModel)]="memberIdProperties.MemberIdValue"
    (ngModelChange)="onMemberIdChange(MemberIdValue.invalid && (MemberIdValue.touched || MemberIdValue.dirty))"
    pattern="^[a-zA-Z0-9]{9,}$"
    required>
  <label 
    ID="MemberIdValueErrormsg" 
    *ngIf="MemberIdValue.invalid && MemberIdValue.touched && MemberIdValue.dirty"
    class="invalid-feedback">
      {{memberIdProperties.ErrorMessage ||'invalid format'}}
  </label>
</div>
