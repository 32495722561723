export class UserNameProperties {

    public UserNameValue: string;
    public UserNamePlaceholder: string;
    public UserNameMessage: string;
    public LabelText: string;
    public TabIndex: string;
    public Regex: string;
    public ErrorMessage: string;
    public UserNameAlreadyTakenMessage: string;
    public UsernameAndPasswordSameMessage: string;
}
