<app-user-header [userHeaderProperties]="userHeaderProperties">
</app-user-header>

<!-- Main Registration Wizard Form-->
<div class="container justify-content-center user-main-registration">
  
    <div class="user-form-body">
      <div class="registration-frame" *ngIf="stepNumber < 6">
        <div class="container">
          <div class="progress-row progress-background">
              <div class="progress-column">
                  <div>
                      <div class="progress-left" [ngClass]="{active: stepNumber >= 1}"></div>
                      <div class="progress-indicator" [ngClass]="{active: stepNumber >= 1}">
                          <div class="progress-check fa-duotone fa-check add-cursor" *ngIf="stepNumber > 1" (click)="goBackToStepOne()">
                          </div>
                      </div>
                      <div class="progress-right" [ngClass]="{active: stepNumber >= 2}"></div>
                  </div>
              </div>
              <div class="progress-column">
                  <div>
                      <div class="progress-left" [ngClass]="{active: stepNumber >= 2}"></div>
                      <div class="progress-indicator" [ngClass]="{active: stepNumber >= 2}">
                          <div class="progress-check fa-duotone fa-check add-cursor" *ngIf="stepNumber > 2" (click)="goBackToStepTwo()"></div>
                      </div>
                      <div class="progress-right" [ngClass]="{active: stepNumber >= 3}"></div>
                  </div>
              </div>
              <div class="progress-column">
                  <div>
                      <div class="progress-left" [ngClass]="{active: stepNumber >= 3}"></div>
                      <div class="progress-indicator" [ngClass]="{active: stepNumber >= 3}">
                          <div class="progress-check fa-duotone fa-check add-cursor" *ngIf="stepNumber > 3" (click)="goBackToStepThree()"></div>
                      </div>
                      <div class="progress-right" [ngClass]="{active: stepNumber >= 4}"></div>
                  </div>
              </div>
              <div class="progress-column">
                  <div>
                      <div class="progress-left" [ngClass]="{active: stepNumber >= 4}"></div>
                      <div class="progress-indicator" [ngClass]="{active: stepNumber >= 4}">
                          <div class="progress-check fa-duotone fa-check add-cursor" *ngIf="stepNumber > 4" (click)="stepNumber = 4"></div>
                      </div>
                      <div class="progress-right" [ngClass]="{active: stepNumber >= 5}"></div>
                  </div>
              </div>
              <div class="progress-column">
                  <div>
                      <div class="progress-left" [ngClass]="{active: stepNumber >= 5}"></div>
                      <div class="progress-indicator" [ngClass]="{active: stepNumber >= 5}">
                          <div class="progress-check fa-duotone fa-check" *ngIf="isSuccessfulRegister"></div>
                      </div>
                      <div class="progress-right" [ngClass]="{active: isSuccessfulRegister}"></div>
                  </div>
              </div>
          </div>
        </div>
        <form #registrationForm="ngForm">
          <fieldset>
            <div>
              <!-- STEP 1: Member Information Step -->
              <div *ngIf="stepNumber == 1">
                <div class="container-fluid">
                  <div class="row justify-content-center">
                    <div class="col-md-8">
                      <h1 ID="memberInfoTitle" style="text-align: center">
                        {{
                          registrationTemplate.MemberInformationTitle ||
                            "member information"
                        }}
                      </h1>
                      <div
                        *ngIf="
                          isStepOneSubmit &&
                          (!registration.CIN || !registration.DateOfBirth)
                        "
                        class="alert alert-danger"
                      >
                        <i class="fa-duotone fa-ban" aria-hidden="true"></i>
                        <strong>Error!</strong>
                        {{ registrationTemplate.RequiredMessage }}
                      </div>
                      <app-member-id
                        [memberIdProperties]="memberIdProperties"
                        (emitMemberId)="onMemberIdChange($event)"
                      >
                      </app-member-id>

                      <app-date-of-birth
                        [dateOfBirthProperties]="dateOfBirthProperties"
                        (dateOfBirthEValue)="dateOfBirthChanged($event)"
                      >
                      </app-date-of-birth>

                      <div class="col-md-12 text-right">
                        <div class="col-md-12">
                          <button
                            ID="btnBackToUserType"
                            class="btn btn-link"
                            type="button"
                            (click)="redirectToMainRegistration()">
                            {{ registrationTemplate.BackButtonLabel || "back" }}
                          </button>
                          <button
                            ID="btnToSecondStep"
                            class="btn btn-primary"
                            type="button"
                            tabindex="{{ step1ForwardTabIndex }}"
                            (click)="validateFirstStep()"
                          >
                            {{ registrationTemplate.NextButtonLabel || "next" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- SETP 2: User Information Step -->
              <div *ngIf="stepNumber == 2">
                <div class="container-fluid">
                  <div class="row justify-content-center">
                    <div class="col-md-8">
                      <h1 ID="userInfoTitle" style="text-align: center">
                        {{
                          registrationTemplate.UserInformationTitle ||
                            "user information"
                        }}
                      </h1>
                      <div
                        *ngIf="
                          isStepTwoSubmit &&
                          (!registration.UserName ||
                            !registration.Password ||
                            !registration.ConfirmPassword)
                        "
                        class="alert alert-danger"
                      >
                        <i class="fa-duotone fa-ban" aria-hidden="true"></i>&nbsp;
                        <strong>Error!</strong>&nbsp;
                        {{ registrationTemplate.RequiredMessage }}
                      </div>
                      <div
                        *ngIf="isStepTwoSubmit && !isValidUserNamePassword"
                        class="alert alert-danger"
                      >
                        <i class="fa-duotone fa-ban" aria-hidden="true"></i>&nbsp;
                        <strong>Error!</strong>&nbsp;
                        {{
                          registrationTemplate.UsernameAndPasswordCannotBeTheSameError
                        }}
                      </div>
                      <app-username
                        [userNameProperties]="userNameProperties"
                        [IsValidUserNamePassword]="isValidUserNamePassword"
                        (emitUserName)="onUserNameChange($event)"
                      >
                      </app-username>

                      <div style="margin-top: 35px">
                        <app-password
                          [passwordProperties]="passwordProperties"
                          [userName]="registration.UserName"
                          (emitPassword)="onPasswordChange($event)"
                        >
                        </app-password>
                      </div>
                      <div class="col-md-12 text-right">
                        <div class="col-md-12">
                          <button
                            ID="btnGoBackToFirstStep"
                            class="btn btn-link"
                            type="button"
                            (click)="goBackToStepOne()"
                          >
                            {{ registrationTemplate.BackButtonLabel || "back" }}
                          </button>
                          <button
                            ID="btnGoToThirdStep"
                            class="btn btn-primary"
                            type="button"
                            tabindex="{{ step2ForwardTabIndex }}"
                            (click)="validateSecondStep()">
                            {{ registrationTemplate.NextButtonLabel || "next" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- STEP 3: User Information Step continued- Step -->
              <div *ngIf="stepNumber == 3">
                <div class="container-fluid">
                  <div class="row justify-content-center">
                    <div class="col-md-8">
                      <h1 ID="userInfoContTitle" style="text-align: center">
                        {{
                          registrationTemplate.UserInformationContTitle ||
                            "user information (cont'd)"
                        }}
                      </h1>
                      <div
                        *ngIf="isStepThreeSubmit && !registration.Email"
                        class="alert alert-danger"
                      >
                        <i class="fa-duotone fa-ban" aria-hidden="true"></i>
                        <strong>Error!</strong>
                        {{ registrationTemplate.RequiredMessage }}
                      </div>

                      <app-user-phone
                        [phoneProperties]="phoneProperties"
                        (emitPhone)="onPhoneChange($event)"
                      >
                      </app-user-phone>

                      <app-user-email
                        [emailProperties]="emailProperties"
                        (emitEmail)="onEmailChange($event)"
                      >
                      </app-user-email>

                      <div class="col-md-12 text-right">
                        <div class="col-md-12">
                          <button
                            ID="btnGoBackToSecondStep"
                            class="btn btn-link"
                            type="button"
                            (click)="goBackToStepTwo()">
                            {{ registrationTemplate.BackButtonLabel || "back" }}
                          </button>
                          <button
                            ID="btnGoToFourthStep"
                            class="btn btn-primary"
                            type="button"
                            tabindex="{{ step3ForwardTabIndex }}"
                            (click)="validateThirdStep()">
                            {{ registrationTemplate.NextButtonLabel || "next" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- STEP 4: Security Questions and Final Register Step-->
              <div *ngIf="stepNumber == 4">
                <div class="container-fluid">
                  <div class="row justify-content-center">
                    <div class="col-md-8">
                      <h1 ID="successMessageTitle" style="text-align: center">
                        {{
                          registrationTemplate.SecurityQuestionsLabel ||
                            "security questions"
                        }}
                      </h1>

                      <app-security-question
                        name="securityQuestion"
                        id="securityQuestion"
                        #securityQuestions
                        [(ngModel)]="kba"
                        [securityQuestionProperties]="securityQuestionProperties"
                        (ngModelChange)="onSecurityQuestionChange($event)"
                      >
                      </app-security-question>

                      <div class="col-md-12 text-right">
                        <div class="col-md-12">
                          <button
                            ID="btnGoBackToThirdStep"
                            class="btn btn-link"
                            type="button"
                            (click)="goBackToStepThree()">
                            {{ registrationTemplate.BackButtonLabel || "back" }}
                          </button>
                          <button
                            ID="btnRegister"
                            class="btn btn-primary"
                            type="button"
                            tabindex="{{ step4ForwardTabIndex }}"
                            (click)="validateRegister()">
                            {{ registrationTemplate.RegisterButton || "submit" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- STEP 5: Thank you and completion Step-->
              <div *ngIf="stepNumber == 5" (stepEnter)="enterFinalStep()">
                <div class="container justify-content-center">
                  <div style="text-align: center; margin: 40px">
                    <h1 ID="successMessageTitle" *ngIf="isSuccessfulRegister">
                      {{ registrationTemplate.SuccessMessage || "thank you!" }}
                    </h1>
                    <p *ngIf="!isSuccessfulRegister">{{ errorMessage }}</p>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  
  <div class="container justify-content-center user-footer">
    <p style="text-align: center">
      {{
        registrationTemplate.Footer ||
          "if you need further assistance, please contact CalOptima Customer Service at 1-888-587-8088"
      }}
      <a href="{{ customerServicePhoneNumberLink }}">{{
        registrationTemplate.CustomerServicePhoneNumber
      }}</a>
    </p>

    <ul class="nav justify-content-center">
      <li class="nav-item">
        <a class="nav-link" href="{{ ForgotUsernameUrl }}">{{
          registrationTemplate.ForgotUserNameLinkLabel || "forgot username"
        }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{ ForgotPasswordUrl }}">{{
          registrationTemplate.ForgotPasswordLinkLabel || "forgot password"
        }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{ LoginUrl }}">{{
          registrationTemplate.LoginLinkLabel || "login"
        }}</a>
      </li>
    </ul>
  </div>
</div>
