
<div class="form-group">
    <label class="col-form-label">
        {{userNameProperties.LabelText || 'user name'}}
    </label>
    <div class="input-message">
        {{userNameProperties.UserNameMessage || 'user name instructions'}}
    </div>
    <input 
        type="text" 
        [(ngModel)]="userNameProperties.UserNameValue" 
        class="form-control" 
        id="registationUserName"  
        name="registationUserName" 
        tabindex="{{userNameProperties.TabIndex}}"
        placeholder="{{userNameProperties.UserNamePlaceholder||'user name*'}}"
        [ngClass]="{'is-invalid': (!IsValidUserNamePassword || !isAvailable || registationUserName.invalid) && registationUserName.touched && registationUserName.dirty}"
        #registationUserName="ngModel"
        (ngModelChange)="onUserNameChange(registationUserName.invalid && (registationUserName.touched || registationUserName.dirty))"
        pattern="[a-zA-Z0-9_@.#-]{5,}[a-zA-Z0-9_@#-]$"   
        (blur)="onBlur(registationUserName.invalid && (registationUserName.touched || registationUserName.dirty))"
        required/>
    <label ID="registationUserNameErrorMsg" 
        *ngIf="registationUserName.invalid && registationUserName.touched && registationUserName.dirty" 
        class="invalid-feedback">
        {{userNameProperties.ErrorMessage || 'incorrect format'}}
    </label>
    <label ID="registationUserNameErrorAlreadyTaken"  
        *ngIf="!isChecking && !isAvailable && registationUserName.touched && registationUserName.dirty" 
        class="invalid-feedback">
        {{userNameProperties.UserNameAlreadyTakenMessage || 'user name already taken'}}
    </label>
    <label 
    ID="registationUserNameErrorAlreadyTaken"  
    *ngIf="!IsValidUserNamePassword && registationUserName.touched && registationUserName.dirty" 
        class="invalid-feedback">
        {{userNameProperties.UsernameAndPasswordSameMessage   || "password cannot be the same as username"}}
    </label> 
</div>


