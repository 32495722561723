<form #f2="ngForm" > 
  <div class={{passwordProperties.InputContainerRowStyle}}>
  <div class={{passwordProperties.InputContainerStyle}}>
    <div class="form-group margin-15">
      <label class={{passwordProperties.LabelStyle}}>
        {{passwordProperties.PasswordLabelText   || "password"}}
      </label>
      <div>
        <input 
          type="password" 
          [(ngModel)]="passwordProperties.PasswordValue" 
          #passwordValue = "ngModel"
          class={{passwordProperties.InputStyle}}
          id="passwordValue" 
          name="passwordValue"
          placeholder="{{passwordProperties.PasswordPlaceholder}}"
          [ngClass]="(passwordComplexityCount < 2 || !isPasswordCorrectLength || !isValidUserNamePassword)? checkForInValid(passwordValue.touched, passwordValue.dirty): checkForValid(passwordValue.touched, passwordValue.dirty)"
          (ngModelChange)="onPasswordChange(f2)"
          tabindex={{passwordProperties.PasswordTabIndex}}
          required
        />
        <label *ngIf="!passwordValue.touched || !passwordValue.dirty"></label>
        <label 
          ID="passwordSuccessMsg" 
          class="valid-feedback" style="text-align:left">
          {{passwordProperties.PasswordMeetsCritieraSuccessMessage   || "correct password format"}}
        </label>
        <label 
          ID="passwordErrorMsg" 
          *ngIf="(passwordComplexityCount < 2 && isPasswordCorrectLength) && passwordValue.touched && passwordValue.dirty"
          class="invalid-feedback" style="text-align:left">
          {{passwordProperties.PasswordFormatError   || "incorrect password format"}}
        </label>
        <label 
          ID="passwordErrorLengthMsg"
          *ngIf="!isPasswordCorrectLength && passwordValue.touched && passwordValue.dirty"
          class="invalid-feedback" style="text-align:left">
          {{passwordProperties.PasswordLengthErrorMessage   || "password must contain at least seven (7) characters."}}
        </label>  
        <label 
          ID="passwordErrorUsernameSameAsPassword"
          *ngIf="!isValidUserNamePassword && passwordValue.touched && passwordValue.dirty"
          class="invalid-feedback" style="text-align:left">
          {{passwordProperties.UsernameAndPasswordCannotBeTheSameError   || "Password cannot be the same as username"}}
        </label> 
         
      </div>
    </div>
  </div>

  <div class={{passwordProperties.InputContainerStyle}}>
    <div class="form-group margin-15">
      <label class={{passwordProperties.LabelStyle}}>
        {{passwordProperties.ConfirmPasswordLabelText  || "confirm new password"}}
      </label>
      <div>
          <input 
            type="password" 
            [(ngModel)]="passwordProperties.ConfirmPasswordValue"   
            class={{passwordProperties.InputStyle}}
            #confirmPasswordValue = "ngModel"
            id="confirmPasswordValue" 
            name="confirmPasswordValue"
            placeholder="{{passwordProperties.ConfirmPasswordPlaceholder}}"
            [ngClass]="(passwordProperties.ConfirmPasswordValue !== passwordProperties.PasswordValue)? checkForInValid(true, confirmPasswordValue.dirty) : checkForValid(true, confirmPasswordValue.dirty)"
            (ngModelChange)="checkIfPasswordIsValid(f2)"
            tabindex={{passwordProperties.ConfirmPasswordTabIndex}}
            required
          />
          <label *ngIf=" !confirmPasswordValue.dirty"></label>
          <label 
            ID="passwordConfirmSuccessMsg" 
            class="valid-feedback" style="text-align:left">
            {{passwordProperties.PasswordMatchSuccessMessage  || "passwords match."}}
          </label>
          <label 
            ID="confirmPasswordErrorMsg" 
            class="invalid-feedback" style="text-align:left">
            {{passwordProperties.PasswordNoMatchError  || "passwords do not match."}}
          </label>   
      </div>
    </div>
  </div>
</div>

  <div class={{passwordProperties.AcceptanceCriteriaContainerStyle}} style="margin-top:0px;">
    <label *ngIf="passwordProperties.AcceptanceCriteriaLabel" class="{{passwordProperties.LabelStyle}}">
      {{passwordProperties.AcceptanceCriteriaLabel}}
    </label>
    <table cellpadding="2">
      <tr>
        <td style="vertical-align: top;padding-top:10px">
          <div *ngIf="!passwordValue.dirty && passwordProperties.ShowGreyCircle" class="fa-duotone fa-circle fa-2x grey-circle"></div>
          <div *ngIf="!isPasswordCorrectLength && passwordValue.dirty" class="fa-duotone fa-times-circle fa-2x x-mark-red"></div>
          <div *ngIf="isPasswordCorrectLength && passwordValue.dirty" class="fa-duotone fa-check progress-bar-check-blue"></div>
        </td>
        <td style="padding-left: 20px;">
          {{passwordProperties.PasswordLengthMessage  || "your password must be at least seven (7) characters long."}}
        </td>
      </tr>
      <tr>
        <td style="vertical-align: top;padding-top:10px">
          <div *ngIf="!passwordValue.dirty && passwordProperties.ShowGreyCircle" class="fa-duotone fa-circle fa-2x grey-circle"></div>
          <div *ngIf="passwordComplexityCount < 2  && passwordValue.dirty" class="fa-duotone fa-times-circle fa-2x x-mark-red"></div>
          <div *ngIf="passwordComplexityCount >= 2 && passwordValue.dirty" class="fa-duotone fa-check progress-bar-check-blue"></div>
        </td>
        <td style="padding-left: 20px;padding-top:12px">
          {{passwordProperties.PasswordMeetComplexityMessage  || "password must contain at least two (2) of the following:"}} 
          <!-- <div class="container-password-criteria"> -->
            <li>{{passwordProperties.PasswordMeetNumericalMessage  || "number (0-9)."}} 
            <li>{{passwordProperties.PasswordMeetSymbolMessage  || "symbol (!, @, #, $, %, *, etc.)."}} 
            <li>{{passwordProperties.PasswordMeetUpperAlphaMessage  || "uppercase English letter (A-Z)."}}
            <li>{{passwordProperties.PasswordMeetLowerAlphaMessage  || "lowercase English letter (a-z)."}}
            <!-- </div> -->
        </td>
      </tr>
    </table>
  </div>
</form>