
<app-user-header
    [userHeaderProperties]="userHeaderProperties">
</app-user-header>

<div class="main-wrapper">
    <div class="background-container">
        <div class="tab-box-container">
            <div class="tab-box-sub2">
                <div class="tab-box-button-container">
                    <a href="{{passwordResetRelativePath}}">
                        <div class="tab-button-inactive">
                            {{forgotUsernameTemplate.TabTitleResetPassword || "reset password"}} 
                        </div>
                    </a>
                    <a href="{{forgotUserNameRelativePath}}">
                        <div class="tab-button-active tbox-color">
                            {{forgotUsernameTemplate.TabTitle   || "forgot username"}}
                        </div>
                    </a>
                </div>
                <div class="tab-box-sub">
                    <div class="tab-box-progress-container">
                        <div class="progress-bar-wrapper" style="width:75%">
                        <div class="progress-bar-inner">
                            <!-- Begin First Node-->
                        
                            <div class="progress-bar-check-wrapper">
                                <div
                                *ngIf="isInPending1" 
                                    class="progress-bar-check-blue"> 
                                <span class="fa-duotone fa-check"></span> 
                                </div>
                                <div
                                    *ngIf="!isInPending1"  
                                    class="progress-bar-check-gray"> 
                                <span class="fa-duotone fa-check"></span> 
                                </div>
                            </div>
                            <!-- End First Node-->

                            <!-- Begin Second Node-->
                            <div class="progress-bar-separator">
                                <div 
                                    *ngIf="!isInPending2" 
                                    class="progress-bar-separator-inner-gray"> 
                                </div>
                                <div 
                                    *ngIf="isInPending2" 
                                    class="progress-bar-separator-inner-blue"> 
                                </div>
                            </div>
                    
                            <div class="progress-bar-check-wrapper">
                                <div
                                *ngIf="!isInPending2"  
                                    class="progress-bar-check-gray"> 
                                <span class="fa-duotone fa-check"></span> 
                                </div>
                                <div
                                    *ngIf="isInPending2" 
                                    class="progress-bar-check-blue"> 
                                    <span class="fa-duotone fa-check"></span> 
                                </div
                                >
                            </div>
                            <!-- End Second Node-->

                            <!-- Begin Third Node-->
                            <div class="progress-bar-separator">
                                <div 
                                    *ngIf="!isInSuccess" 
                                    class="progress-bar-separator-inner-gray"> 
                                </div>
                                <div 
                                *ngIf="isInSuccess" 
                                    class="progress-bar-separator-inner-blue"> 
                                </div>
                            </div>
                    
                            <div class="progress-bar-check-wrapper">
                                <div *ngIf="!isInSuccess" class="progress-bar-check-gray"> 
                                    <span class="fa-duotone fa-check"></span> 
                                </div>
                                <div *ngIf="isInSuccess" class="progress-bar-check-blue"> 
                                    <span class="fa-duotone fa-check"></span> 
                                </div>
                            </div>
                            <!-- End Third Node-->

                        </div>
                        </div>
                    </div>

                    <div class="override-input-container" style="text-align: left;color:#808080;font-size:14px" *ngIf='!requiresTwoFactor'>
                        {{forgotUsernameTemplate.SubMessage   || "please enter your member id below."}}
                    </div>

                    <div *ngIf="errorMessage">
                        <div class="login-msg alert alert-danger">
                        <i class="fa-duotone fa-ban fa-lg" aria-hidden="true"></i>
                        &nbsp;<span [innerHTML]="errorMessage"></span>
                        </div>
                    </div>

                    <div class="tab-box-body" *ngIf='!requiresTwoFactor'>
                        <form #f="ngForm" (ngSubmit)="onForgotUsername()" > 
                            <div class="override-input-container">
                                <div class="override-input-label w-100">
                                    {{forgotUsernameTemplate.MemberIDLabel || "member id"}}
                                </div>
                                
                                <div style="width:100%;">
                                    <input 
                                    type="text" 
                                    [(ngModel)]="memberId" 
                                    class="form-control"  
                                    id="memberId" 
                                    name="memberId" />
                                </div>
                            </div>
                            <div class="override-element-container">
                                <button type="submit" class="override-login-button" >
                                    {{forgotUsernameTemplate.ContinueButton || "continue"}}
                                </button>
                            </div>
                        </form>
                    </div>

                    <div class="tab-box-body" *ngIf='twoFactorSuccess'>
                        <div class="override-element-container user-tab-body" style="margin-top: 0px;">{{forgotUsernameTemplate.ForgotUsernameMessage  || "thank you for requesting retrieval of your username. "}}</div>
                        <div class="override-element-container user-tab-body">{{forgotUsernameTemplate.HereIsYourUsernameLabel   || "here is your username: "}}</div>
                        <div class="override-element-container user-tab-body" style="font-size: 24px;">{{userName}}</div>
                        <div class="override-element-container user-tab-body" [innerHTML]="forgotUsernameTemplate.TroubleLoggingInMessage"></div>
                        <div class="override-element-container user-tab-body" [innerHTML]="forgotUsernameTemplate.VisitDotOrgMessage"></div>
                        <div class="override-element-container user-tab-body-bottom">
                            <button class="override-login-button" (click)="btnGotoLogin()">
                                {{forgotUsernameTemplate.ReturnToMemberPortalLabel   || "return to Member Portal"}}
                            </button>
                        </div>
                    </div>
                    
                    <app-second-factor 
                        *ngIf="requiresTwoFactor && !twoFactorSuccess" 
                        name="challenge" 
                        (ngModel)="twoFactorStatus" 
                        (ngModelChange)="SecondFactorChanged($event)">
                    </app-second-factor>

                    <div class="tab-box-link-container override-input-container" *ngIf="showLinks">
                        <a href="{{LoginUrl}}">
                        <div class="tab-link-bottom-left">
                            {{forgotUsernameTemplate.MemberLoginLabel || "member login"}}
                        </div>
                        </a>
                        <a *ngIf="showRegistrationLink" href="{{registrationPath}}">
                            <div class="tab-link-bottom-right">
                            {{forgotUsernameTemplate.RegisterAsNewUserLabel || "register as new user"}}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br *ngIf="!isSmallScreenWidth">
