import { Component, OnInit, OnDestroy } from '@angular/core';
import { SecureAuthService } from '../shared/services/user.service';
import { Configuration } from '../../app.constants';
import { RegistrationTemplate } from '../shared/registration-template';
import { UserNameProperties } from '../shared/username-properties';
import { PasswordProperties } from '../shared/password-properties';
import { DateOfBirthProperties } from '../../core/shared/date-of-birth-properties';
import { Registration } from '../shared/registration';
import { SecurityQuestionProperties } from '../shared/security-question-properties';
import { UserHeaderProperties } from '../shared/user-header-properties';
import { MemberIdProperties } from '../shared/memberid-properties';
import { PhoneProperties } from '../shared/phone-properties';
import { EmailProperties } from '../shared/email-properties';
import { FamilyRelationship } from '../shared/family-relationship';
import { CommonService } from '../../core/shared/services/common.service';
import { State } from '../../core/shared/state';
import { RegistrationResponse } from '../shared/registration-response';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { Subscription } from 'rxjs';
import { PasswordResetTemplate } from '../shared/passwordreset-template';
import { KBA } from '../shared/kba';

@Component({
  selector: 'app-personal-representative-registration',
  templateUrl: './personal-representative-registration.component.html',
  providers: [CommonService],
  styleUrls: ['./personal-representative-registration.component.scss']
})
export class PersonalRepresentativeRegistrationComponent implements OnInit, OnDestroy {
   public passwordResetTemplate: PasswordResetTemplate = new PasswordResetTemplate();
  public userNameProperties = new UserNameProperties();
  public passwordProperties = new PasswordProperties();
  public memberDobProperties = new DateOfBirthProperties();
  public dateOfBirthPRepProperties = new DateOfBirthProperties();
  public securityQuestionProperties = new SecurityQuestionProperties();
  public userHeaderProperties = new UserHeaderProperties();
  public memberIdProperties = new MemberIdProperties();
  public phoneProperties = new PhoneProperties();
  public phonePRepProperties = new PhoneProperties();
  public userPhoneProperties = new PhoneProperties();
  public emailProperties = new EmailProperties();
  public registrationTemplate = new RegistrationTemplate();
  public registrationResponse = new RegistrationResponse();
  public kba = new KBA();

  public ForgotUsernameUrl: string;
  public ForgotPasswordUrl: string;
  public LoginUrl: string;
  public MainRegistrationUrl: string;
  public CalOptimaDotOrgUrl: string;
  public registration = new Registration();
  public errorObject: any;

  public errorMessage: string;
  public restrictedMessage: string;
  public postRegistrationMessage: string;
  public postRegistrationButtonLabel: string;
  public customerServicePhoneNumberLink: string;

  public isStepOneSubmit: boolean = false;
  public isStepTwoSubmit: boolean = false;
  public isStepThreeSubmit: boolean = false;

  public isValidPasswordFormat: boolean;
  public isPasswordMatch: boolean;
  public isValidEmail: boolean;
  public isValidMemberId: boolean;
  public isSuccessfulRegister: boolean;
  public isValidUserPhoneFormat: boolean;
  public isValidUserName: boolean;
  public isUserNameAvailable: boolean;
  public isValidAttestation: boolean;
  public isValidUserNamePassword: boolean;

  public isValidMemberFirstName: boolean;
  public isValidMemberLastname: boolean;
  public isValidMemberZipCode: boolean;
  public isValidMemberDob: boolean;
  public isValidMemberPhoneFormat: boolean;
  public isValidMemberDobRange: boolean;

  public isValidPRepFirstName: boolean;
  public isValidPRepMiddleName: boolean;
  public isValidPRepLastname: boolean;
  public isValidPRepDob: boolean;
  public isValidPRepPhoneFormat: boolean;
  public isValidPRepAddress: boolean;
  public isValidPRepCity: boolean;
  public isValidPRepState: boolean;
  public isValidPRepZipCode: boolean;
  public isValidPRepDobRange: boolean;

  public isAllRequiredFieldsStepOne: boolean;
  public isAllRequiredFieldsStepTwo: boolean;
  public isAllRequiredFieldsStepThree: boolean;

  public hasRegistrationOccurred: boolean;

  public delayTimeToLogin: number;
  public states = new Array<State>();
  public familyRelationshipList: Array<FamilyRelationship> = new Array<FamilyRelationship>();

  public controlTabIndex: number = 1;

  // First step controls for tab index
  public memberFirstNameTabIndex: number;
  public memberLastNameTabIndex: number;
  public memberZipCodeTabIndex: number;
  public step1ForwardTabIndex: number;

  // second step controls for tab index
  public prepFirstNameTabIndex: number;
  public prepMiddleInitialTabIndex: number;
  public prepLastNameTabIndex: number;
  public prepRelationshipTabIndex: number;
  public prepAddressTabIndex: number;
  public prepCityTabIndex: number;
  public prepStateTabIndex: number;
  public prepZipCodeTabIndex: number;
  public step2ForwardTabIndex: number;

  // Third step controls for tab index
  public userNameTabIndex: number;
  public passwordTabIndex: number;
  public confirmPasswordTabIndex: number;
  public emailTabIndex: number;
  public userPhoneTabIndex: number;
  public step3ForwardTabIndex: number;

  public step4ForwardTabIndex: number;

  public declarationCheckBoxTabIndex: number;
  public registerTabIndex: number;

  public stepNumber: number = 1;

  private subscription: Subscription;
  
  constructor(
    private secureAuthService: SecureAuthService,
    private config: Configuration,
    private commonService: CommonService,
    private languageService: LanguageService
    ) { }

  ngOnInit() {
    this.CalOptimaDotOrgUrl = 'https://www.caloptima.org';
    this.states = this.commonService.getStates();
    this.registration.personalRepresentative.State = '-1';

    this.isSuccessfulRegister = false;

    this.delayTimeToLogin = 5000;
    this.ForgotUsernameUrl = this.config.ForgotUsernameUrl;
    this.ForgotPasswordUrl = this.config.PasswordResetUrl;
    this.LoginUrl = this.config.LoginUrl;
    this.MainRegistrationUrl = this.config.MainRegistrationUrl;

    try {

      this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if (change.propName === 'languageCode') {
          this.loadFamilyRelationList();
          this.loadView();
        }
      });

      this.registration.personalRepresentative.RelationshipId = this.config.ParentRelationshipCode;
    } catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public keyDownFunction($event: KeyboardEvent) {
    if ($event.keyCode !== 13) {
      return $event;
    }
    else {
      $event.preventDefault();
      event.stopPropagation();
    }
  }

   returnNewTabIndex(): number {
    return this.controlTabIndex++;
  }
  private SetTabIndex(): void {

    // First step controls for tab index
    this.memberFirstNameTabIndex = this.returnNewTabIndex();
    this.memberLastNameTabIndex = this.returnNewTabIndex();
    this.memberZipCodeTabIndex = this.returnNewTabIndex();
    this.phoneProperties.TabIndex = String(this.returnNewTabIndex());
    this.memberIdProperties.TabIndex = String(this.returnNewTabIndex());
    this.memberDobProperties.TabIndexMonth = String(this.returnNewTabIndex());
    this.memberDobProperties.TabIndexDay = String(this.returnNewTabIndex());
    this.memberDobProperties.TabIndexYear = String(this.returnNewTabIndex());
    this.step1ForwardTabIndex = this.returnNewTabIndex();

    // Second Step Controls
    this.prepFirstNameTabIndex = this.returnNewTabIndex();
    this.prepMiddleInitialTabIndex = this.returnNewTabIndex();
    this.prepLastNameTabIndex = this.returnNewTabIndex();
    this.prepRelationshipTabIndex = this.returnNewTabIndex();
    this.dateOfBirthPRepProperties.TabIndexMonth = String(this.returnNewTabIndex());
    this.dateOfBirthPRepProperties.TabIndexDay = String(this.returnNewTabIndex());
    this.dateOfBirthPRepProperties.TabIndexYear = String(this.returnNewTabIndex());
    this.prepAddressTabIndex = this.returnNewTabIndex();
    this.prepCityTabIndex = this.returnNewTabIndex();
    this.prepStateTabIndex = this.returnNewTabIndex();
    this.prepZipCodeTabIndex = this.returnNewTabIndex();
    this.phonePRepProperties.TabIndex = String(this.returnNewTabIndex());
    this.step2ForwardTabIndex = this.returnNewTabIndex();

    this.userNameProperties.TabIndex = String(this.returnNewTabIndex());
    this.passwordProperties.PasswordTabIndex = String(this.returnNewTabIndex());
    this.passwordProperties.ConfirmPasswordTabIndex = String(this.returnNewTabIndex());
    this.emailProperties.TabIndex = String(this.returnNewTabIndex());
    this.userPhoneProperties.TabIndex = String(this.returnNewTabIndex());
    this.step3ForwardTabIndex = this.returnNewTabIndex() ;

    this.securityQuestionProperties.Question1TabIndex = String(this.returnNewTabIndex());
    this.securityQuestionProperties.Answer1TabIndex = String(this.returnNewTabIndex());
    this.securityQuestionProperties.Question2TabIndex = String(this.returnNewTabIndex());
    this.securityQuestionProperties.Answer2TabIndex = String(this.returnNewTabIndex());
    this.step4ForwardTabIndex = this.returnNewTabIndex();

    this.declarationCheckBoxTabIndex = this.returnNewTabIndex();
    this.registerTabIndex = this.returnNewTabIndex();

  }

  private loadView() {
    this.secureAuthService.getRegistrationContent().subscribe(
      response => {
        this.registrationTemplate = response;

        this.customerServicePhoneNumberLink = 'tel:' + this.registrationTemplate.CustomerServicePhoneNumber;
        this.loadDateOfBirthView();
        this.loadSecurityQuestionsView();
        this.loadUserNameView();
        this.loadUserHeaderView();
        this.loadMemberIdView();
        this.loadPhoneView();
        this.loadEmailView();
        this.SetTabIndex();

        this.secureAuthService.getPasswordResetTemplate().subscribe(
        responsePR => {
          this.passwordResetTemplate = responsePR;
          this.loadPasswordView();
        },
        error => {
          this.errorMessage = <any>error;
        });

        if (this.hasRegistrationOccurred) {
          if (this.isSuccessfulRegister) {
            this.enterFinalStep();
          } else {
            this.parseErrorMessage(this.errorObject);
            this.enterFinalStep();
          }
        }
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  private loadFamilyRelationList(): void {
    this.secureAuthService.getFamilyRelationships().subscribe(
      response => {
        this.familyRelationshipList = this.translateFamilyRelationship(response);
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  private loadEmailView(): void {
    this.emailProperties.EmailPlaceholder = this.registrationTemplate.EmailAddressInputMessage;
    this.emailProperties.EmailLabelText = this.registrationTemplate.Email;
    this.emailProperties.ErrorMessage = this.registrationTemplate.InvalidEmailError;
    this.emailProperties.Message = this.registrationTemplate.EmailAddressUserMessage;
  }

  private loadPhoneView(): void {
    this.phoneProperties.Placeholder = this.registrationTemplate.MobilePhoneInputMessage;
    this.phoneProperties.LabelText = this.registrationTemplate.Cell;
    this.phoneProperties.ErrorMessage = this.registrationTemplate.InvalidPhoneError;
    this.phoneProperties.Name = 'memberPhone';
    this.phoneProperties.IsRequired = true;
    this.phoneProperties.RequiredMessage = this.registrationTemplate.PhoneRequiredMessage;

    this.phonePRepProperties.Placeholder = this.registrationTemplate.MobilePhoneInputMessage;
    this.phonePRepProperties.LabelText = this.registrationTemplate.PersonalRepPhoneNumberLabel ;
    this.phonePRepProperties.ErrorMessage = this.registrationTemplate.InvalidPhoneError;
    this.phonePRepProperties.Name = 'prepPhone';

    this.phonePRepProperties.IsRequired = true;
    this.phonePRepProperties.RequiredMessage = this.registrationTemplate.PhoneRequiredMessage;

    this.userPhoneProperties.Placeholder = this.registrationTemplate.MobilePhoneInputMessage;
    this.userPhoneProperties.LabelText = this.registrationTemplate.UserInformationPhoneLabel;
    this.userPhoneProperties.ErrorMessage = this.registrationTemplate.InvalidPhoneError;
    this.userPhoneProperties.Name = 'userPhone';
    this.userPhoneProperties.IsRequired = false;
    this.userPhoneProperties.RequiredMessage = this.registrationTemplate.PhoneRequiredMessage;
  }

  private loadMemberIdView(): void {
    this.memberIdProperties.MemberIdLabelText = this.registrationTemplate.CIN;
    this.memberIdProperties.ErrorMessage = this.registrationTemplate.InvalidFormatError;
    this.memberIdProperties.MemberIdPlaceholder = this.registrationTemplate.MemberIDInputMessage;
    this.memberIdProperties.MemberIdMessage = '';
    this.memberIdProperties.SampleMemberIDCardTitle = this.registrationTemplate.SampleMemberIDCardTitle;
    this.memberIdProperties.SampleIDCardOkLabel = this.registrationTemplate.SampleIDCardOkLabel;
  }

  private loadUserHeaderView(): void {
    this.userHeaderProperties.MainTitle = this.registrationTemplate.PersonalRepMainTitle;
  }

  private loadSecurityQuestionsView(): void {
    this.securityQuestionProperties.HideAnswersLabel = this.registrationTemplate.HideAnswersLabel;
    this.securityQuestionProperties.Question1Label = this.registrationTemplate.SecretQuestion1;
    this.securityQuestionProperties.Question1AnswerLabel = this.registrationTemplate.SecretAnswer1;
    this.securityQuestionProperties.Question2Label = this.registrationTemplate.SecretQuestion2;
    this.securityQuestionProperties.Question2AnswerLabel = this.registrationTemplate.SecretAnswer2;
    this.securityQuestionProperties.SecurityQuestionsDropdownMessageLabel
      = this.registrationTemplate.SecurityQuestionsDropdownMessageLabel;
    this.config.UpdateSecurityQuestions(this.registrationTemplate);
    this.securityQuestionProperties.Question1Values = this.config.SecurityQuestions;
    this.securityQuestionProperties.Question2Values = this.config.SecurityQuestions;
    this.kba.SecretQuestion1 = this.config.SecurityQuestions[0];
    this.kba.SecretQuestion2 = this.config.SecurityQuestions[1];
  }

  private loadPasswordView(): void {
    this.passwordProperties.PasswordLabelText = this.registrationTemplate.Password;
    this.passwordProperties.ConfirmPasswordLabelText = this.registrationTemplate.ConfirmPassword;
    this.passwordProperties.PasswordFormatError = this.registrationTemplate.PasswordFormatError;
    this.passwordProperties.PasswordLengthErrorMessage = this.passwordResetTemplate.PasswordLengthErrorMessage;
    this.passwordProperties.UsernameAndPasswordCannotBeTheSameError = this.passwordResetTemplate.UsernameAndPasswordCannotBeTheSameError;
    this.passwordProperties.PasswordMatchSuccessMessage = this.passwordResetTemplate.PasswordMatchSuccessMessage;
    this.passwordProperties.PasswordNoMatchError = this.registrationTemplate.PasswordNoMatchError;
    this.passwordProperties.PasswordLengthMessage = this.passwordResetTemplate.PasswordLengthMessage;
    this.passwordProperties.PasswordMeetComplexityMessage = this.passwordResetTemplate.PasswordMeetComplexityMessage;
    this.passwordProperties.PasswordMeetsCritieraSuccessMessage = this.passwordResetTemplate.PasswordMeetsCritieraSuccessMessage ;
    this.passwordProperties.PasswordMeetNumericalMessage = this.passwordResetTemplate.PasswordMeetNumericalMessage;
    this.passwordProperties.PasswordMeetSymbolMessage = this.passwordResetTemplate.PasswordMeetSymbolMessage;
    this.passwordProperties.PasswordMeetUpperAlphaMessage = this.passwordResetTemplate.PasswordMeetUpperAlphaMessage;
    this.passwordProperties.PasswordMeetLowerAlphaMessage = this.passwordResetTemplate.PasswordMeetLowerAlphaMessage;

    this.passwordProperties.PasswordPlaceholder = this.registrationTemplate.PasswordInputMessage;
    this.passwordProperties.ConfirmPasswordPlaceholder = this.registrationTemplate.ConfirmPasswordInputMessage;

    this.passwordProperties.LabelStyle = 'col-form-label';
    this.passwordProperties.InputContainerStyle = '';
    this.passwordProperties.InputStyle = 'form-control';
    this.passwordProperties.AcceptanceCriteriaLabel = this.registrationTemplate.AcceptanceCriteria;
    this.passwordProperties.AcceptanceCriteriaContainerStyle = '';
    this.passwordProperties.ShowGreyCircle = true;
  }

  private loadDateOfBirthView(): void {
    this.memberDobProperties.LabelText = this.registrationTemplate.DateOfBirth;
    this.memberDobProperties.ErrorMessage = this.registrationTemplate.InvalidFormatError;
    this.memberDobProperties.IsCorrectRange = (obj) => { return true; };

    this.memberDobProperties.MonthName = 'memberDobMonth';
    this.memberDobProperties.DayName = 'memberDobDay';
    this.memberDobProperties.YearName = 'memberDobYear';
    this.memberDobProperties.ErrorMsgName = 'memberDobErrorMsg';

    this.memberDobProperties.Placeholder = this.registrationTemplate.DateOfBirthInputMessage;

    this.dateOfBirthPRepProperties.LabelText = this.registrationTemplate.PersonalRepDateOfBirthLabel ;
    this.dateOfBirthPRepProperties.ErrorMessage = this.registrationTemplate.InvalidFormatError;
    this.dateOfBirthPRepProperties.Placeholder = this.registrationTemplate.DateOfBirthInputMessage;
    this.dateOfBirthPRepProperties.IsCorrectRange = this.commonService.isAge18OrOver;

    this.dateOfBirthPRepProperties.MonthName = 'prepDobMonth';
    this.dateOfBirthPRepProperties.DayName = 'prepDobDay';
    this.dateOfBirthPRepProperties.YearName = 'prepDobYear';
    this.dateOfBirthPRepProperties.ErrorMsgName = 'prepDobErrorMsg';

    this.dateOfBirthPRepProperties.DateRangeErrorMessage = this.registrationTemplate.PersonalRepIsUnder18ErrorMessage;
  }

  private loadUserNameView(): void {
    this.userNameProperties.LabelText = this.registrationTemplate.UserName;
    this.userNameProperties.UserNamePlaceholder = this.registrationTemplate.UserNameInputMessage;
    this.userNameProperties.UserNameMessage = this.registrationTemplate.PersonalRepUserNameMessage;
    this.userNameProperties.ErrorMessage = this.registrationTemplate.InvalidFormatError;
    this.userNameProperties.UserNameAlreadyTakenMessage = this.registrationTemplate.UserNameAlreadyTakenMessage;

     this.userNameProperties.UsernameAndPasswordSameMessage = this.registrationTemplate.UsernameAndPasswordCannotBeTheSameError;
  }

  public onMemberDobChanged($event): void {
    this.registration.DateOfBirth = $event.dateOfBirthValue;
    this.isValidMemberDob = $event.isValid;
    this.isValidMemberDobRange = $event.isCorrectRange;
  }

  public dateOfBirthPRepChanged($event): void {
    this.registration.personalRepresentative.DateOfBirth = $event.dateOfBirthValue;
    this.isValidPRepDob = $event.isValid;
    this.isValidPRepDobRange = $event.isCorrectRange;
  }

  public onPasswordChange($event): void {
    this.registration.Password = $event.password;
    this.registration.ConfirmPassword = $event.confirmPassword;
    this.isValidPasswordFormat = $event.isValid;
    this.isValidUserNamePassword = this.commonService.IsValidUserNamePasswordCombo(this.registration.UserName, this.registration.Password);
  }

  public onUserNameChange($event): void {
    this.registration.UserName = $event.userName;
    this.isUserNameAvailable = $event.isAvailable;
    this.isValidUserName = $event.isValid;
    this.isValidUserNamePassword = this.commonService.IsValidUserNamePasswordCombo(this.registration.UserName, this.registration.Password);
  }

  public onMemberIdChange($event): void {
    this.registration.CIN = $event.memberIdValue;
    this.isValidMemberId = $event.isValid;
  }

  public onMemberPhoneChange($event): void {
    this.registration.MemberPhone = $event.phoneValue;
    this.isValidMemberPhoneFormat = $event.isValid;
  }

  public onPhonePRepChange($event): void {
    this.registration.personalRepresentative.Phone = $event.phoneValue;
    this.isValidPRepPhoneFormat = $event.isValid;
  }

  public onEmailChange($event): void {
    this.registration.Email = $event.emailValue;
    this.isValidEmail = $event.isValid;
  }

  public onSecurityQuestionChange($event): void {
    if ($event.SecretQuestion1 !== undefined &&
        $event.SecretQuestion2 !== undefined) {
        this.registration.SecretAnswer1 = $event.SecretAnswer1;
        this.registration.SecretQuestion1 = $event.SecretQuestion1.value;
        this.registration.SecretAnswer2 = $event.SecretAnswer2;
        this.registration.SecretQuestion2 = $event.SecretQuestion2.value;
    }
  }

  public onMemberFirstnameChange(invalidValue: boolean): void {
    this.isValidMemberFirstName = !invalidValue;
  }

  public onMiddleInitialChange(invalidValue: boolean): void {
    this.isValidPRepMiddleName = !invalidValue;
  }

  public onMemberLastnameChange(invalidValue: boolean): void {
    this.isValidMemberLastname = !invalidValue;
  }

  public onMemberZipCodeChange(invalidValue: boolean): void {
    this.isValidMemberZipCode = !invalidValue;
  }

  public onRelationToMemberChange(familyRelation: string): void {
  }

  public onPRepFirstnameChange(invalidValue: boolean): void {
    this.isValidPRepFirstName = !invalidValue;
  }

  public onPRepLastnameChange(invalidValue: boolean): void {
    this.isValidPRepLastname = !invalidValue;
  }

  public onPrepAddressChange(invalidValue: boolean): void {
    this.isValidPRepAddress = !invalidValue;
  }

  public onPrepCityChange(invalidValue: boolean): void {
    this.isValidPRepCity = !invalidValue;
  }

  public onPRepZipCodeChange(invalidValue: boolean): void {
    this.isValidPRepZipCode = !invalidValue;
  }

  public onPRepPhoneChange($event): void {
    this.registration.personalRepresentative.Phone = $event.phoneValue;
    this.isValidPRepPhoneFormat = $event.isValid;
  }

  public onPhoneUserChange($event): void {
    this.registration.Phone = $event.phoneValue;
    this.isValidUserPhoneFormat = $event.isValid;
  }

  public redirectToMainRegistration(): void {
    window.location.href = this.MainRegistrationUrl;
  }

  public redirectToLogin(): void {
    window.location.href = this.LoginUrl;
  }

  public redirectToCalOptimaOrg(): void {
    window.location.href = this.CalOptimaDotOrgUrl;
  }

  public ValidateFirstStep(): void {
    this.isStepOneSubmit = true;

    if ( this.isValidMemberFirstName
      && this.isValidMemberLastname
      && this.isValidMemberId
      && this.isValidMemberZipCode
      && this.isValidMemberPhoneFormat) {
      this.isAllRequiredFieldsStepOne = true;
    } else {
      this.isAllRequiredFieldsStepOne = false;
    }

    if (this.isAllRequiredFieldsStepOne) {
      this.stepNumber = 2;
    }
  }

  public validateSecondStep(): void {
    this.isStepTwoSubmit = true;

     // optional value set invalid to false
     if (this.registration.personalRepresentative.MiddleInitial === undefined) {
      this.isValidPRepMiddleName = true;
    }

    if (this.registration.personalRepresentative.State === '-1') {
      this.isValidPRepState = false;
    } else {
      this.isValidPRepState = true;
    }

    if (this.isValidPRepFirstName
      && this.isValidPRepMiddleName
      && this.isValidPRepLastname
      && this.isValidPRepDob
      && this.isValidPRepPhoneFormat
      && this.isValidPRepAddress
      && this.isValidPRepCity
      && this.isValidPRepState
      && this.isValidPRepZipCode) {
        this.isAllRequiredFieldsStepTwo = true;
      } else {
        this.isAllRequiredFieldsStepTwo = false;
      }

     if (this.isAllRequiredFieldsStepTwo
        && this.isValidPRepDobRange
        ) {
      this.stepNumber = 3;
    }
  }

  public validateThirdStep(): void {
    this.isStepThreeSubmit = true;
    if (this.isUserNameAvailable
      && this.isValidUserName
      && this.isValidPasswordFormat
      && this.isValidEmail
      && this.isValidUserNamePassword) {
      this.isAllRequiredFieldsStepThree = true;
    } else {
      this.isAllRequiredFieldsStepThree = false;
    }

    if (this.registration.Phone === undefined
      || this.registration.Phone === '') {
        this.isValidUserPhoneFormat = true;
      }

   if (this.isUserNameAvailable
        && this.isValidUserName
        && this.isValidPasswordFormat
        && this.isValidUserPhoneFormat
        && this.isValidEmail
        && this.isValidUserNamePassword) {
      this.stepNumber = 4;
    }
  }

  public postRegisterClick(): void {
    if (this.isSuccessfulRegister) {
     this.redirectToLogin();
    } else {
     this.redirectToCalOptimaOrg();
    }
  }

  public enterFinalStep(): void {
    if (this.isSuccessfulRegister) {
      this.postRegistrationButtonLabel = this.registrationTemplate.PostRegistrationButtonLoginLabel;
      
      if (this.registrationResponse.isPending) {
        this.postRegistrationMessage = this.registrationTemplate.PostRegistrationSuccessReviewMessage;
      } else {
        this.postRegistrationMessage = this.registrationTemplate.PostRegistrationSuccessMessage;
      }
    } else {
      this.postRegistrationButtonLabel = this.registrationTemplate.PostRegistrationButtonDotOrgLabel;
    }
  }

  public parseErrorMessage(errorObj): void {
    let errorTranslated = "";
   
    if (errorObj && errorObj.error && errorObj.error.message) {
      errorTranslated = this.translateErrorText(errorObj.error.message);
      this.errorMessage = errorTranslated + ' ' + this.registrationTemplate.PostRegistrationErrorMessage;
    } 
    else {
      //TODO: Add sitecore content and multi-languages
      this.errorMessage = "We're sorry! Your registration did not work. Please try again. If you run into this issue again, please get in touch with Customer Service.";
    }
  }

  public validateRegister(): void {
    // Restricting test user email in PROD
    const testEmail = this.registration.Email.toLowerCase();

    if (
      testEmail.endsWith('@caloptima.org')
      && this.config.Environment != Configuration.ENV.DEV
      && this.config.Environment != Configuration.ENV.UAT
      && (
        testEmail !== 'membersportalscrumteam@caloptima.org'
        || !this.registration.CIN.toLowerCase().startsWith('default')
      )
    ) {
      this.hasRegistrationOccurred = true;
      this.isSuccessfulRegister = false;
      this.restrictedMessage = 'Registration is not allowed.';
      this.enterFinalStep();
      return;
    }
    // End restriction

    if (this.isValidAttestation) {
      this.registration.RepresentativeAccessFlag = this.config.Active;
      this.registration.personalRepresentative.Cin = this.registration.CIN;
      this.registration.personalRepresentative.Username = this.registration.UserName;

      if (this.registration.personalRepresentative.MiddleInitial === undefined
          || this.registration.personalRepresentative.MiddleInitial === '') {
        this.registration.personalRepresentative.MiddleInitial = ' ';
      }

      if (!this.hasRegistrationOccurred) {
        
        this.secureAuthService.registerUser(this.registration).subscribe(
        response => {
          this.registrationResponse = response;
          this.hasRegistrationOccurred = true;
          this.isSuccessfulRegister = true;
          this.stepNumber = 6;
          this.enterFinalStep();
          this.memberRegistrationComplete();
        },
        error => {
          this.stepNumber = 6;
          this.errorObject = error;
          this.parseErrorMessage(error);
          this.isSuccessfulRegister = false;
          this.hasRegistrationOccurred = true;
          this.enterFinalStep();
        });
      } else {
        this.secureAuthService.updateRegisterUser(this.registration).subscribe(
          response => {
            this.stepNumber = 6;
            this.registrationResponse = response;
            this.hasRegistrationOccurred = true;
            this.isSuccessfulRegister = true;
            this.enterFinalStep();
            this.memberRegistrationComplete();
          },
          errorUpdate => {
            this.stepNumber = 6;
            this.errorObject = errorUpdate;
            this.parseErrorMessage(errorUpdate);
            this.isSuccessfulRegister = false;
            this.hasRegistrationOccurred = true;
            this.enterFinalStep();
          }
        );
      }
    }
  }
  
  private translateErrorText(errorText:string): string
  {
    if(!errorText) return '';

    if(errorText.includes('CININC')) return this.registrationTemplate.ErrorCode_CININC;
    if(errorText.includes('USRSTG')) return this.registrationTemplate.ErrorCode_USRSTG;
    if(errorText.includes('USRNMM')) return this.registrationTemplate.ErrorCode_USRNMM;
    if(errorText.includes('USRUTB')) return this.registrationTemplate.ErrorCode_USRUTB;
    if(errorText.includes('DOBINC')) return this.registrationTemplate.ErrorCode_DOBINC;
    if(errorText.includes('USRXTB')) return this.registrationTemplate.ErrorCode_USRXTB;
    if(errorText.includes('USRUSE')) return this.registrationTemplate.ErrorCode_USRUSE;
    if(errorText.includes('NOTELG')) return this.registrationTemplate.ErrorCode_NOTELG;
    if(errorText.includes('CINUSR')) return this.registrationTemplate.ErrorCode_CINUSR;
    if(errorText.includes('USRMIN')) return this.registrationTemplate.ErrorCode_USRMIN;
    if(errorText.includes('USR13M')) return this.registrationTemplate.ErrorCode_USR13M;
    if(errorText.includes('WARN14')) return this.registrationTemplate.ErrorCode_WARN14;
    return errorText;
  }
  private translateFamilyRelationship(relationshipList:Array<FamilyRelationship>):Array<FamilyRelationship>
  {
    let translatedResult = relationshipList.map(relation=>{
      let r = {...relation} ;
      if (r.Code === 'CAL2' && this.registrationTemplate.Relationship_CAL2) r.Description = this.registrationTemplate.Relationship_CAL2;
      else if (r.Code === 'CAL3' && this.registrationTemplate.Relationship_CAL3) r.Description = this.registrationTemplate.Relationship_CAL3;
      else if (r.Code === 'CAL5' && this.registrationTemplate.Relationship_CAL5) r.Description = this.registrationTemplate.Relationship_CAL5;
      else if (r.Code === 'CAL6' && this.registrationTemplate.Relationship_CAL6) r.Description = this.registrationTemplate.Relationship_CAL6;
      else if (r.Code === 'CAL9'&& this.registrationTemplate.Relationship_CAL9) r.Description = this.registrationTemplate.Relationship_CAL9;
      return r;
    });

    return translatedResult;
  }

  public memberRegistrationComplete(): void {
    this.secureAuthService.memberRegistrationComplete(this.registration).subscribe();
  }

  public goBacktoStep1(): void {
    this.revertAttestation();
    this.stepNumber = 1;  
  }

  public goBacktoStep2(): void {
    this.revertAttestation();
    this.stepNumber = 2;  
  }

  public goBacktoStep3(): void {
    this.revertAttestation();
    this.stepNumber = 3;  
  }

  public goBacktoStep4(): void {
    this.revertAttestation();
    this.stepNumber = 4;  
  }

  public revertAttestation(): void {
    if (this.isValidAttestation == true) {
      this.isValidAttestation = false;
    }
  }
}
