import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { Configuration } from '../../app.constants';
import { SubSink } from 'subsink';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { UserHeaderProperties } from '../shared/user-header-properties';
import { AuthenticationService,FindMemberUserRequest } from '@caloptima/authentication';
import { SecureAuthService } from '../shared/services/user.service';
import { TwoFactorStatus } from '../../member-portal/shared/enum';
import { PasswordResetTemplate } from '../shared/passwordreset-template';
import { FormGroupDirective } from '@angular/forms';
import { CommonService } from '../../core/shared/services/common.service';
import { PasswordProperties } from '../shared/password-properties';
import { PasswordComponent } from '../shared/components/password/password.component';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  providers: [CommonService],
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  @ViewChild(PasswordComponent) passComp: PasswordComponent;

  public passwordProperties: PasswordProperties = new PasswordProperties();
  public passwordResetTemplate: PasswordResetTemplate = new PasswordResetTemplate();
  public LoginUrl: string =  this.config.LoginUrl;
  public registrationPath: string = this.config.MainRegistrationRelativePath;
  public forgotUserNameRelativePath: string = this.config.ForgotUsernameRelativePath;
  public passwordResetRelativePath: string = this.config.PasswordResetRelativePath;
  public errorMessage: string;
  public userHeaderProperties = new UserHeaderProperties();
  public username: string;
  public password: string;
  public confirmPassword: string;
  public loginFailed: boolean = false;
  public requiresTwoFactor = false;
  public loginStatus: TwoFactorStatus = TwoFactorStatus.START;
  private subscriptions = new SubSink();
  public showPasswordUpdate: boolean = false;
  public showRegistrationLink: boolean = true;
  public isInPending1: boolean;
  public isInPending2: boolean;
  public isInLastStep: boolean;
  public isValidUserNamePassword: boolean;

  public passwordMessage: string;
  public successMessage: string;
  public passwordComplexityCount: number;
  public isPasswordCorrectLength: boolean;
  public isValidPassword: boolean;
  public smallScreenWidth: number = 335;
  public isSmallScreenWidth: boolean;
  public areBothPasswordsEmpty: boolean;

  private IsPasswordExpired: boolean = false;
  private ErrorStatusMessageReset: string = 'Your password cannot be reset at this time, please notify an administrator.';
  private ErrorStatusMessageChange: string = 'Your password cannot be changed at this time, please notify an administrator.';
  private ErrorStatusMessageValidatePassword: string = 'Unable to validate your original password.';
  private ErrorStatusMessagePassAndUserNameEqual: string = 'Username and Password cannot be the same.';
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumbers: boolean;
  hasNonalphas: boolean;

  constructor(private authService: AuthenticationService,
    private siteCoreService: SecureAuthService,
    private languageService: LanguageService,
    private config: Configuration,
    private commonService: CommonService
    ) {

    }

  ngOnInit() {
    try {
      const passRestRequest = sessionStorage.getItem(this.config.PasswordResetRequestLocalName);
      if (passRestRequest === this.config.PasswordResetRequestLocalValue ) {
        window.location.href = this.config.SelfServiceRestartUrl;
      }

      this.onResize();
      this.resetVariables();
      const PasswordExpiredLocalName: string = localStorage.getItem(this.config.PasswordExpiredLocalName);
      if (PasswordExpiredLocalName !== null) {
        const savedUserName = atob(PasswordExpiredLocalName);
        this.username = savedUserName;
        this.IsPasswordExpired = true;
        this.showPasswordUpdateControl();
      }
      this.subscriptions.sink = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if (change.propName === 'languageCode') {
          this.loadView();
        }
      });
    } catch (ex) {
      throw ex;
    }
  }

  private resetVariables(): void {
    this.isInPending1 = false;
    this.isInPending2 = false;
    this.showPasswordUpdate = false;
    this.isInLastStep = false;
    this.showRegistrationLink = true;
    this.errorMessage = '';
    this.password = '';
    this.confirmPassword = '';
    this.areBothPasswordsEmpty = true;
  }

  private loadView() {
    this.subscriptions.sink = this.siteCoreService.getPasswordResetTemplate().subscribe(
      response => {
        this.passwordResetTemplate = response;
        this.successMessage = this.passwordResetTemplate.PasswordSuccessMessage;
        this.loadHeaderView();
        this.loadPasswordView();
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  private loadPasswordView(): void {
    this.passwordProperties.PasswordLabelText = this.passwordResetTemplate.NewPasswordLabel;
    this.passwordProperties.ConfirmPasswordLabelText = this.passwordResetTemplate.ConfirmNewPasswordLabel;
    this.passwordProperties.PasswordMeetsCritieraSuccessMessage = this.passwordResetTemplate.PasswordMeetsCritieraSuccessMessage;
    this.passwordProperties.PasswordFormatError = this.passwordResetTemplate.PasswordFormatError;
    this.passwordProperties.PasswordLengthErrorMessage = this.passwordResetTemplate.PasswordLengthErrorMessage;
    this.passwordProperties.UsernameAndPasswordCannotBeTheSameError = this.passwordResetTemplate.UsernameAndPasswordCannotBeTheSameError;
    this.passwordProperties.ConfirmPasswordLabelText = this.passwordResetTemplate.ConfirmNewPasswordLabel;
    this.passwordProperties.PasswordMatchSuccessMessage = this.passwordResetTemplate.PasswordMatchSuccessMessage;
    this.passwordProperties.PasswordNoMatchError = this.passwordResetTemplate.PasswordDontMatchErrorMessage;
    this.passwordProperties.PasswordLengthMessage = this.passwordResetTemplate.PasswordLengthMessage;
    this.passwordProperties.PasswordMeetNumericalMessage = this.passwordResetTemplate.PasswordMeetNumericalMessage;
    this.passwordProperties.PasswordMeetSymbolMessage = this.passwordResetTemplate.PasswordMeetSymbolMessage;
    this.passwordProperties.PasswordMeetUpperAlphaMessage = this.passwordResetTemplate.PasswordMeetUpperAlphaMessage;
    this.passwordProperties.PasswordMeetLowerAlphaMessage = this.passwordResetTemplate.PasswordMeetLowerAlphaMessage;
    this.passwordProperties.PasswordMeetComplexityMessage = this.passwordResetTemplate.PasswordMeetComplexityMessage;

    this.passwordProperties.LabelStyle = 'override-input-label no-top-margin w-100';
    this.passwordProperties.InputContainerStyle = 'override-input-container';
    this.passwordProperties.InputStyle = 'form-control override-input-text';
    this.passwordProperties.AcceptanceCriteriaLabel = '';
    this.passwordProperties.AcceptanceCriteriaContainerStyle = 'override-element-container user-tab-body no-top-margin';
    this.passwordProperties.ShowGreyCircle = false;
  }

  onPasswordReset() {
    this.errorMessage = '';
    const memuser = new FindMemberUserRequest();
    memuser.username = this.username;
    memuser.clientId = this.config.ClientId;
    this.subscriptions.sink = this.authService.findMemberUser(memuser).subscribe((res) => {
      this.requiresTwoFactor = true;
      this.isInPending1 = true;
      this.isInPending2 = false;
      this.showPasswordUpdate = false;
      this.isInLastStep = false;
      this.showRegistrationLink = false;
    },
    error => {
      if (error.error === this.config.ErrorMessageStatusAccountLocked) {
        this.showErrorMessage(this.passwordResetTemplate.ErrorMessageAccountLocked);
      } else {
        this.showErrorMessage(this.passwordResetTemplate.UserNameInvalidErrorMessage);
      }
    });
  }

  private processSuccessPasswordUpdate(): void {
    this.isInLastStep = true;
    this.removePasswordResetLocalValue();
    if (this.IsPasswordExpired) {
      localStorage.removeItem(this.config.RememberMeLocalName);
      this.IsPasswordExpired = false;
    }
  }

  private processFailurePasswordUpdate(error): void {
    if (error.status === 500 &&
      (error.error === this.ErrorStatusMessageReset
      || error.error === this.ErrorStatusMessageChange)) {
      this.showErrorMessage(this.passwordResetTemplate.PasswordHistoryErrorMessage);
      this.passComp.ResetForm();
      // form.resetForm();
    } else if (error.status === 500 && error.error === this.ErrorStatusMessageValidatePassword) {
      localStorage.setItem(this.config.LoginPasswordExpiredLocalName, btoa(this.config.LoginPasswordExpiredLocalValue));
      window.location.href = this.LoginUrl;
    } else if (error.status === 500 && error.error === this.ErrorStatusMessagePassAndUserNameEqual) {
      this.showErrorMessage(this.passwordResetTemplate.UsernameAndPasswordCannotBeTheSameError);
      this.passComp.ResetForm();
      // form.resetForm();
    } else {
      this.resetVariables();
      this.showErrorMessage(this.passwordResetTemplate.GeneralErrorMessage);
    }
  }

  public onPasswordUpdate(): void {
    this.errorMessage = '';

    if (this.isValidPassword) {
      if (!this.IsPasswordExpired) {
        this.subscriptions.sink = this.authService.resetPassword(this.password, this.config.ClientId)
        .subscribe(
          () => {
            this.processSuccessPasswordUpdate();
          },
          error => {
            this.processFailurePasswordUpdate(error);
        });
      } else {
        this.subscriptions.sink = this.authService.changePassword(this.password, this.config.ClientId)
        .subscribe(
          () => {
            this.processSuccessPasswordUpdate();
          },
          error => {
            this.processFailurePasswordUpdate(error);
        });
      }

    }
  }

  private removePasswordResetLocalValue() {
    const PasswordExpiredLocalName: string = localStorage.getItem(this.config.PasswordExpiredLocalName);
    if (PasswordExpiredLocalName !== null) {
      localStorage.removeItem(this.config.PasswordExpiredLocalName);
    }
  }

  public showPasswordUpdateControl() {
    this.requiresTwoFactor = false;
    this.isInPending1 = true;
    this.isInPending2 = true;
    this.showPasswordUpdate = true;
    this.isInLastStep = false;
    this.errorMessage = '';
  }

  public SecondFactorChanged(value) {
    if (value) {
      if (value.status === TwoFactorStatus.START) {
        this.requiresTwoFactor = false;
        this.isInPending1 = false;
        this.isInPending2 = false;
        this.showPasswordUpdate = false;
        this.isInLastStep = false;
      } else if (value.status === TwoFactorStatus.PENDING_1) {
        this.isInPending1 = true;
        this.isInPending2 = false;
        this.showPasswordUpdate = false;
        this.isInLastStep = false;
        this.showRegistrationLink = false;
        this.errorMessage = '';
      } else if (value.status === TwoFactorStatus.PENDING_2) {
        this.isInPending1 = true;
        this.isInPending2 = true;
        this.showPasswordUpdate = false;
        this.isInLastStep = false;
        this.showRegistrationLink = false;
        this.errorMessage = '';
      }
      else if (value.status === TwoFactorStatus.SUCCESS) {
        this.showPasswordUpdateControl();
      }
      else if (value.status === TwoFactorStatus.PASSCODE_ERROR) {
        if (value.message === '') {
          this.showErrorMessage(this.config.defaultIncorrectPasscodeMessage);
        } else {
          this.showErrorMessage(value.message);
        }
      }
      else if (value.status === TwoFactorStatus.ANSWER_ERROR) {
        if (value.message === '') {
          this.showErrorMessage(this.config.defaultIncorrectAnswersMessage);
        } else {
          this.showErrorMessage(value.message);
        }
      }
      else if (value.status === TwoFactorStatus.ERROR) {
        this.requiresTwoFactor = false;
        this.loginFailed = true;
        this.showErrorMessage(this.passwordResetTemplate.GeneralErrorMessage);
      }
    }
  }

public onPasswordChange($event): void {
    this.password = $event.password;
    this.isValidPassword = $event.isValid;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.removePasswordResetLocalValue();
  }

  public loadHeaderView(): void {
    this.userHeaderProperties.MainTitle = this.passwordResetTemplate.ApplicationTitle;
  }

  private showErrorMessage(message: string): void {
    this.errorMessage = message;
  }

  public redirectToLogin(): void {
    window.location.href = this.LoginUrl;
  }

  @HostListener('window:resize', [])
  private onResize() {
    if (window.innerWidth <= this.smallScreenWidth) {
      this.isSmallScreenWidth = true;
    } else {
      this.isSmallScreenWidth = false;
    }
  }
}

