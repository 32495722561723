import { PersonalRepresentative } from "./personal-representative";

export class Registration {
  public CIN: string;
  public FirstName: string;
  public LastName: string;
  public DateOfBirth: string;
  public Email: string;
  public UserName: string;
  public Password: string;
  public ConfirmPassword: string;
  public SecretQuestion1: string;
  public SecretAnswer1: string;
  public SecretQuestion2: string;
  public SecretAnswer2: string;
  public SecretQuestion3: string;
  public SecretAnswer3: string;
  public Address1: string;
  public Address2: string;
  public City: string;
  public State: string;
  public ZipCode: string;
  public Phone: string;
  public Cell: string;
  public MiddleName: string;
  public RepresentativeAccessFlag: string;
  public MemberPhone: string;
  public personalRepresentative: PersonalRepresentative = new PersonalRepresentative();
}
