import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { PasswordProperties } from '../../password-properties';
import { FormGroupDirective } from '@angular/forms';
import { PasswordResetTemplate } from '../../passwordreset-template';
import { CommonService } from '../../../../core/shared/services/common.service';
import { Configuration } from '../../../../app.constants';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  @Input() passwordProperties = new PasswordProperties();
  @Input() passwordResetTemplate = new PasswordResetTemplate();
  @Input() userName: string;
  @Output() emitPassword = new EventEmitter();

  //public password: string;
  //public confirmPassword: string;
  public isPasswordCorrectLength: boolean;
  public isPasswordMatch: boolean;
  public isPasswordNoMatch: boolean;
  public isValidUserNamePassword: boolean;
  public passForm;

  public hasUpperCase: boolean;
  public hasLowerCase: boolean;
  public hasNumbers: boolean;
  public hasNonalphas: boolean;
  public isValidPassword: boolean;
  public passwordComplexityCount: number;

  constructor(private commonService: CommonService, private config: Configuration) { }

  ngOnInit() {
  }

  public ResetForm() {
    this.passForm.resetForm();
  }
  public checkForValid($touched: boolean, $dirty: boolean) {
    if ($touched && $dirty) {
      return 'is-valid';
    } else {
      return '';
    }
  }

  public checkForInValid($touched: boolean, $dirty: boolean) {
    if ($touched && $dirty) {
      return 'is-invalid';
    } else {
      return '';
    }
  }
    public checkIfPasswordIsValid(form: FormGroupDirective) {
    this.isValidUserNamePassword = this.commonService.IsValidUserNamePasswordCombo(this.userName, this.passwordProperties.PasswordValue);

    if (this.passwordComplexityCount >= 2 &&
      this.isPasswordCorrectLength &&
      this.passwordProperties.PasswordValue === this.passwordProperties.ConfirmPasswordValue &&
      this.isValidUserNamePassword
      ) {
    this.isValidPassword = true;
    } else {
      this.isValidPassword = false;
    }
    if (this.passwordProperties.PasswordValue === '' && this.passwordProperties.ConfirmPasswordValue === '') {
      form.resetForm();
    }
    this.emitPassword.emit({
      password: this.passwordProperties.PasswordValue,
      confirmPassword: this.passwordProperties.ConfirmPasswordValue,
      isValid: this.isValidPassword
    });
  }

    public checkIfPasswordsMatch() {
    if (this.passwordProperties.PasswordValue.length <= 0 || this.passwordProperties.ConfirmPasswordValue.length <= 0) {
      return false;
    } else {
      if (this.passwordProperties.ConfirmPasswordValue !== this.passwordProperties.PasswordValue) {
        return false;
      } else {
        return true;
      }
    }
  }


  public onPasswordChange(form: FormGroupDirective) {
    this.passForm = form;
    this.passwordComplexityCount = 0;
    if (this.passwordProperties.PasswordValue.length < this.config.PasswordMinimumLength) {
      this.isPasswordCorrectLength = false;
    } else {
      this.isPasswordCorrectLength = true;
    }

    this.hasUpperCase = /[A-Z]/.test(this.passwordProperties.PasswordValue);
    this.hasLowerCase = /[a-z]/.test(this.passwordProperties.PasswordValue);
    this.hasNumbers = /\d/.test(this.passwordProperties.PasswordValue);
    this.hasNonalphas = /\W/.test(this.passwordProperties.PasswordValue);

    if (this.hasUpperCase) {
      this.passwordComplexityCount++;
    }

    if (this.hasLowerCase) {
      this.passwordComplexityCount++;
    }

    if (this.hasNumbers) {
      this.passwordComplexityCount++;
    }

    if (this.hasNonalphas) {
      this.passwordComplexityCount++;
    }

    this.checkIfPasswordIsValid(form);
  }
}


