<app-user-header
 [userHeaderProperties]="userHeaderProperties">
</app-user-header>

<div class="main-wrapper">
  <div class="background-container">
      <div class="tab-box-container">
        <div class="tab-box-sub">
          <div class="tab-title override-input-container">
            {{loginHelpTemplate.MainTitle || "login Help"}}
          </div>
          <div class="tab-title-intro override-input-container">
              <div  [innerHTML] = "loginHelpTemplate.TitleIntro"> </div>
          </div>
          
          <div class="override-element-container">
            <button type="button" class="override-login-button" [routerLink] = "['/user/forgotusername']" >{{loginHelpTemplate.ForgetUsernameButtonLabel || "forget Username"}}</button>
        </div>
        <div class="override-element-container">
            <button type="button" class="override-login-button" [routerLink] = "['/user/passwordreset']" >{{loginHelpTemplate.ResetPasswordButtonLabel || "reset Password"}}</button>
        </div>

          <div class="tab-box-link-container override-input-container">
            <a [routerLink]="['/user/registration']">
              <div class="tab-link-bottom-left">
                {{loginHelpTemplate.RegisterNewUserLinkLabel || "register New User"}}
              </div>
            </a>
            <a [routerLink]="['/user/login']">
              <div class="tab-link-bottom-right">
                {{loginHelpTemplate.LoginLinkLabel || "login"}}
              </div>
            </a>
            </div>

        </div>
      </div>
  </div>
</div>

