import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import {Router} from '@angular/router';
import { SecureAuthService } from '../shared/services/user.service';
import { Configuration } from '../../app.constants';
import { RegistrationTemplate } from '../shared/registration-template';
import { UserHeaderProperties } from '../shared/user-header-properties';
import { Subscription } from 'rxjs';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {

  public registrationTemplate = new RegistrationTemplate();
  public LoginUrl: string;
  public userHeaderProperties = new UserHeaderProperties();
  public customerServicePhoneNumberLink: string;
  public errorMessage: string;
  public isCalOptimaMember: boolean;
  private subscription: Subscription;

  constructor(
    private secureAuthService: SecureAuthService,
    public config: Configuration,
    private router: Router,
    private renderer: Renderer2,
    private languageService: LanguageService
    ) { }
  ngOnInit() {
    this.isCalOptimaMember = true;
    this.LoginUrl = this.config.LoginUrl;

    try {
      this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if (change.propName === 'languageCode'){
          this.loadView();
        }
      });
    } catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private loadView(): void {
    this.secureAuthService.getRegistrationContent().subscribe(
      response => {
        this.registrationTemplate = response;
        this.loadHeaderView();
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  public loadHeaderView(): void {
    this.userHeaderProperties.MainTitle = this.registrationTemplate.MainTitle;
    this.customerServicePhoneNumberLink = 'tel:' + this.registrationTemplate.CustomerServicePhoneNumber;
  }

  public onClick(): void {
    if (this.isCalOptimaMember === true) {
      this.router.navigate(['/memberregistration']);
    } else {
      this.router.navigate(['/personalrepresentativeregistration']);
    }
  }

  public onBack(): void {
    this.redirectToLogin();
  }

  public redirectToLogin(): void {
    window.location.href = this.LoginUrl;
  }

}
