
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserNameProperties } from './../../username-properties';
import { SecureAuthService } from '../../services/user.service';
import { Configuration } from '../../../../app.constants';
import { ValidateUserResponse } from '../../validate-user-response';

@Component({
    selector: 'app-username',
    templateUrl: './username.component.html'
})

export class UserNameComponent implements OnInit {

    public errorMessage: string;
    public isAvailable: boolean;
    public validateUserResponse: ValidateUserResponse = new ValidateUserResponse();
    public isChecking: boolean;
    private minimumCharacterLength: number = 6;

    constructor(private secureAuthService: SecureAuthService,
      public config: Configuration) { }

    @Input() userNameProperties = new UserNameProperties();
    @Input() IsValidUserNamePassword: string;
    @Output() emitUserName = new EventEmitter();

    ngOnInit() {
    }

    private sendValuesToParent(isInvalid: boolean): void {
      this.emitUserName.emit({
        userName: this.userNameProperties.UserNameValue,
        isAvailable: this.isAvailable,
        isValid: !isInvalid
      });
    }

    public onUserNameChange(isInvalid: boolean): void {
      this.sendValuesToParent(isInvalid);
    }

    public onBlur(isInvalid: boolean): void {
      this.isChecking = true;

      if (this.userNameProperties.UserNameValue.length >= this.minimumCharacterLength) {
        this.secureAuthService.validateUser(this.userNameProperties.UserNameValue).subscribe(
          response => {
            this.isChecking = false;
            this.validateUserResponse = response;

            if (this.validateUserResponse.status === this.config.Available) {
              this.isAvailable = true;
            } else {
              this.isAvailable = false;
            }

            this.sendValuesToParent(isInvalid);
          },
          error => {
            this.isChecking = false;
            this.errorMessage = <any>error;
          }
        );
      } else {
        this.isAvailable = true;
      }
    }
}
