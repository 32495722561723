import { KeyValue } from "./keyvalue";


export class SecurityQuestionProperties {
    public Required: boolean;
    public AnswerRequiredMessage: string;
    public SecurityQuestionsLabel: string;
    public SecurityQuestionsDropdownMessageLabel: string;
    public HideAnswersLabel: string;
    public Question1Label: string;
    public Question1Values: Array<KeyValue>;
    public Question1AnswerLabel: string;
    public Question2Label: string;
    public Question2Values: Array<KeyValue>;
    public Question2AnswerLabel: string;
    public Question1TabIndex: string;
    public Question2TabIndex: string;
    public Answer1TabIndex: string;
    public Answer2TabIndex: string;
}
