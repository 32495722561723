<div class="login-main-wrapper">
  <app-user-header
    [userHeaderProperties]="userHeaderProperties">
  </app-user-header>

  <div class="background-container">
    <div class="tab-box-container">
      <div class="tab-box-sub">
        
        <div class="tab-title override-input-container">
          {{loginTemplate.MainTitle || "login"}}
        </div>
        
        <div *ngIf="!requiresTwoFactor" class="tab-title-intro override-input-container">
          {{loginTemplate.SubMessage || "enter your User ID below and click 'Log In' to access the system."}}
        </div>
        <div>
          <div *ngIf="errorMessage" class="login-msg alert alert-danger">
            <i class="fa-duotone fa-ban fa-lg" aria-hidden="true"></i>
            &nbsp;<span [innerHTML]="errorMessage"></span>
          </div>
        </div>
        <div *ngIf="!requiresTwoFactor" class="tab-box-body" style="margin-top:0px">
          <form #f="ngForm" (ngSubmit)="onLogin()" > 
            <div>
          
              <div class="override-input-container">
                <div style="display:flex; flex-direction:row;">
                  <div class="override-input-label" style="align-self: flext-start;">
                    {{loginTemplate.UsernameLabel || "username"}}
                  </div>
                  <div class="override-input-option-container" style="margin-left: auto;">
                      <a href="{{forgotUserNameRelativePath}}" class="override-input-option-link">
                        {{loginTemplate.ForgotUsernameLabel || "forgot"}}
                      </a>
                  </div>
                </div>
                  <div style="width:100%;">
                    <input 
                      type="text" 
                      [(ngModel)]="username" 
                      class="form-control"  
                      placeholder="{{loginTemplate.UsernamePlaceholder || 'username'}}"
                      id="username" 
                      name="username" />
                  </div>
              </div>
           
              <div class="override-input-container">
                <div style="display:flex; flex-direction:row;">
                  <div class="override-input-label" style="align-self: flext-start;">
                    {{loginTemplate.PasswordLabel || "password"}}
                  </div>
                  <div class="override-input-option-container" style="margin-left: auto; ">
                      <a href="{{passwordResetRelativePath}}" class="override-input-option-link">
                        {{loginTemplate.ForgotPasswordLabel || "forgot"}}
                      </a>
                  </div>
                </div>
                  <div style="width:100%;">
                    <input 
                      type="password" 
                      [(ngModel)]="password" 
                      required  
                      class="form-control"  
                      placeholder="{{loginTemplate.PasswordPlaceholder || 'password'}}" 
                      id="password" 
                      name="password" />      
                  </div>
              </div>
            

              <div class="override-element-container">
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input 
                      type="checkbox" 
                      class="custom-control-input" 
                      id="chkRememberMe" 
                      (change)="rememberMe = !rememberMe"
                      [checked]="rememberMe">
                    <label class="custom-control-label user-tab-body" style="font-weight:normal" for="chkRememberMe">{{loginTemplate.RememberMeLabel || "remember me"}}</label>
                  </div>
                </div>
              </div>
              <div class="override-element-container">
                  <button type="submit" class="override-login-button" [disabled]="!f.valid" >{{loginTemplate.LoginButtonLabel || "log in"}}</button>
              </div>
            </div>
          </form>
        </div>

        <app-second-factor 
            *ngIf="requiresTwoFactor" 
            name="challenge" 
            (ngModel)="twoFactorStatus" 
            (ngModelChange)="SecondFactorChanged($event)">
        </app-second-factor>

        <div class="tab-box-link-container override-input-container">
          <a href="{{registrationPath}}">
            <div class="tab-link-bottom-left">
              {{loginTemplate.RegisterNewUserLabel || "register As new user"}}
            </div>
          </a>
          <a href="#/user/loginhelp">
            <div class="tab-link-bottom-right">
              {{loginTemplate.LoginHelpLabel || "login help"}}
            </div>
          </a>
        </div>

      </div>
    </div>  
  </div>
</div>
<br>




  
