export class PersonalRepresentative {
        public PersonalRepresentativeId: string;
        public Cin: string;
        public FirstName: string;
        public MiddleInitial: string;
        public LastName: string;
        public DateOfBirth: string;
        public RelationshipId: string;
        public Username: string;
        public Phone: string;
        public Address: string;
        public City: string;
        public State: string;
        public ZipCode: string;
    }
