import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SecureAuthService } from '../shared/services/user.service';
import { Subscription } from 'rxjs';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { LoginHelpTemplate } from '../shared/login-help-template';
import { UserHeaderProperties } from '../shared/user-header-properties';

@Component({
  selector: 'app-login-help',
  templateUrl: './login-help.component.html',
  styleUrls: ['./login-help.component.scss']
})
export class LoginHelpComponent implements OnInit, OnDestroy {

  public errorMessage: string;
  private subscription: Subscription;
  public loginHelpTemplate: LoginHelpTemplate = new LoginHelpTemplate();
  public userHeaderProperties: UserHeaderProperties = new UserHeaderProperties();
  constructor(    
    private siteCoreService: SecureAuthService,
    private languageService: LanguageService) { 
  }

  ngOnInit() {
    this.errorMessage = '';
    try {
      this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if (change.propName === 'languageCode') {
          this.loadView();
        }
      });
    } catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private loadView() {
    this.siteCoreService.getLoginHelpTemplate().subscribe(
      response => {
        this.loginHelpTemplate = response;
        this.loadHeaderView();
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  public loadHeaderView(): void {
    this.userHeaderProperties.MainTitle = this.loginHelpTemplate.MainTitle;
  }
}
