import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { AuthenticationServiceStatus, AuthenticationFactor, AuthenticationService, AuthHelper, FindMemberUserRequest, LoginResponse } from '@caloptima/authentication';
import { SubSink } from 'subsink';
import { SecureAuthService } from '../shared/services/user.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { Configuration } from '../../app.constants';
import { TwoFactorStatus } from '../../member-portal/shared/enum';
import { ForgotUserNameTemplate } from '../shared/forgotusername-template';
import { UserHeaderProperties } from '../shared/user-header-properties';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss']
})
export class ForgotUsernameComponent implements OnInit, OnDestroy {
  public forgotUsernameTemplate: ForgotUserNameTemplate = new ForgotUserNameTemplate();
  public userHeaderProperties: UserHeaderProperties = new UserHeaderProperties();
  public LoginUrl: string =  this.config.LoginUrl;
  public registrationPath: string = this.config.MainRegistrationRelativePath;
  public forgotUserNameRelativePath: string = this.config.ForgotUsernameRelativePath;
  public passwordResetRelativePath: string = this.config.PasswordResetRelativePath;
  public twoFactorSuccess: boolean = false;
  public requiresTwoFactor = false;
  public twoFactorStatus: TwoFactorStatus = TwoFactorStatus.START;
  public memberId: string;
  public userName: string;
  public errorMessage: string;
  public visitDotOrgContent: string;
  public troubleLoggingInMessage: string;
  public showLinks: boolean = true;
  public showRegistrationLink: boolean = true;
  private subscriptions = new SubSink();

  public isInPending1: boolean;
  public isInPending2: boolean;
  public isInSuccess: boolean;
  public isSmallScreenWidth: boolean;
  public smallScreenWidth: number = 335;

  constructor(private authService: AuthenticationService,
    private siteCoreService: SecureAuthService,
    private languageService: LanguageService,
    private config: Configuration) { }

  ngOnInit() {
    this.resetVariables();

    this.subscriptions.sink = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
      if (change.propName === 'languageCode') {
        this.loadView();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private loadView() {
    this.siteCoreService.getForgotusernameTemplate().subscribe(
      response => {
        this.forgotUsernameTemplate = response;
        this.loadHeaderView();
      },
      error => {
        this.errorMessage = '<b>Error</b> An error occurred processing your request';
      }
    );
  }

  private resetVariables(): void {
    this.isInPending1 = false;
    this.isInPending2 = false;
    this.isInSuccess = false;
    this.showRegistrationLink = true;
    this.errorMessage = '';
  }
  public loadHeaderView(): void {
    this.userHeaderProperties.MainTitle = this.forgotUsernameTemplate.ApplicationTitle;
  }

  public onForgotUsername() {
    this.errorMessage = '';
    const findUserRequest = new FindMemberUserRequest();
      findUserRequest.cin = this.memberId;
      findUserRequest.clientId = this.config.ClientId;

    this.authService.findMemberUser(findUserRequest).subscribe((res) => {
        this.requiresTwoFactor = true;
        this.isInPending1 = true;
        this.isInPending2 = false;
        this.isInSuccess = false;
        this.showRegistrationLink = false;
      },
      error => {
        if (error.error === this.config.ErrorMessageStatusAccountLocked) {
          this.errorMessage = this.forgotUsernameTemplate.ErrorMessageAccountLocked;
        } else {
          this.errorMessage = this.forgotUsernameTemplate.ErrorMessageDefault;
        }
      }
    );
  }

  public SecondFactorChanged(value) {
    if (value.status === TwoFactorStatus.START) {
      this.requiresTwoFactor = false;
      this.isInPending1 = false;
      this.isInPending2 = false;
      this.isInSuccess = false;
    } else if (value.status === TwoFactorStatus.PENDING_1) {
      this.isInPending1 = true;
      this.isInPending2 = false;
      this.isInSuccess = false;
      this.showRegistrationLink = false;
      this.errorMessage = '';
    } else if (value.status === TwoFactorStatus.PENDING_2) {
      this.isInPending1 = true;
      this.isInPending2 = true;
      this.isInSuccess = false;
      this.showRegistrationLink = false;
      this.errorMessage = '';
    } else if (value.status === TwoFactorStatus.PASSCODE_ERROR) {
        if (value.message === '') {
          this.errorMessage = this.config.defaultIncorrectPasscodeMessage;
        } else {
          this.errorMessage = value.message;
        }
    } else if (value.status === TwoFactorStatus.ANSWER_ERROR) {
        if (value.message === '') {
          this.errorMessage = this.config.defaultIncorrectAnswersMessage;
        } else {
          this.errorMessage = value.message;
        }
    } else if (value.status === TwoFactorStatus.SUCCESS) {
      this.authService.forgotUsername(this.config.ClientId).subscribe((resp) => {
        this.twoFactorSuccess = true;
        this.userName = resp.username;
        this.showLinks = false;
        this.errorMessage = '';

        this.isInPending1 = true;
        this.isInPending2 = true;
        this.isInSuccess = true;
      },
      error => {
        this.errorMessage = this.forgotUsernameTemplate.ErrorMessageGeneral;
      });

    } else if (value.status === TwoFactorStatus.ERROR) {
      this.requiresTwoFactor = false;
      this.twoFactorSuccess = false;
      this.errorMessage = this.forgotUsernameTemplate.ErrorMessageGeneral;
    }
  }

  public btnGotoLogin() {
    window.location.href = this.config.LoginUrl;
  }

  @HostListener('window:resize', [])
  private onResize() {
    if (window.innerWidth <= this.smallScreenWidth) {
      this.isSmallScreenWidth = true;
    } else {
      this.isSmallScreenWidth = false;
    }
  }
}
